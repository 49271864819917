import { AreaPopUp } from 'components/AreaPopUp'
import { HomeMapMarkerIcon } from 'components/HomeMapMarker'
import L, { LatLngTuple, LeafletMouseEvent } from 'leaflet'
import 'leaflet.heat'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Circle, MapContainer, Marker, Polygon, Popup, TileLayer, ZoomControl, useMap, useMapEvents } from 'react-leaflet'
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3'
import { Business, Cluster, FetchStatus } from 'shared/types'
import {
  onBusinessClick,
  onClickAddUnsavedHome,
  onClickSearch,
  setCursorSearchRadius,
  useGlobalStore
} from 'store/global'





const createStarIcon = (index: number) => {
  const iconHtml = `
  <div style="position: relative; width: 60px; height: 60px; display: flex; justify-content: center; align-items: center;">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="60" height="60">
      <!-- Star shape -->
      // <svg id="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><defs><style>.cls-1{fill:#ffe300;}.cls-2{fill:#ffe300;opacity:0.08;}.cls-3{fill:none;stroke:#CCBF00;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;}</style></defs><path class="cls-1" d="M109.65,50.86a2.0076,2.0076,0,0,1-.39,2.69L84.38,73.82a2.00441,2.00441,0,0,0-.63,2.17L93.9,107.38a2.00671,2.00671,0,0,1-.76,2.26,1.99437,1.99437,0,0,1-2.38-.07L65.24,89.51a2.01211,2.01211,0,0,0-2.48,0L37.24,109.57a2.00056,2.00056,0,0,1-3.14-2.19L44.25,75.99a2.00441,2.00441,0,0,0-.63-2.17L18.74,53.55a2.0076,2.0076,0,0,1-.39-2.69A2.09662,2.09662,0,0,1,20.09,50H49.23a2.00037,2.00037,0,0,0,1.87-1.3L62.13,19.3a1.995,1.995,0,0,1,3.74,0L76.9,48.7A2.00037,2.00037,0,0,0,78.77,50h29.14A2.09662,2.09662,0,0,1,109.65,50.86Z"/><path class="cls-2" d="M91.48639,99.91553A1.97345,1.97345,0,0,1,90.76,99.57L65.24,79.51a2.01206,2.01206,0,0,0-2.48,0L37.24,99.57a1.97331,1.97331,0,0,1-.72559.3432L34.1,107.38a2.00057,2.00057,0,0,0,3.14,2.19L62.76,89.51a2.01206,2.01206,0,0,1,2.48,0l25.52,20.06a2.00057,2.00057,0,0,0,3.14-2.19Z"/><path class="cls-3" d="M109.65,50.86a2.09662,2.09662,0,0,0-1.74-.86H78.77a2.00037,2.00037,0,0,1-1.87-1.3L65.87,19.3a1.995,1.995,0,0,0-3.74,0L51.1,48.7A2.00037,2.00037,0,0,1,49.23,50H20.09a2.09662,2.09662,0,0,0-1.74.86,2.0076,2.0076,0,0,0,.39,2.69L43.62,73.82a2.00441,2.00441,0,0,1,.63,2.17L34.1,107.38a2.00056,2.00056,0,0,0,3.14,2.19L62.76,89.51a2.01211,2.01211,0,0,1,2.48,0l25.52,20.06a2.00056,2.00056,0,0,0,3.14-2.19L83.75,75.99a2.00441,2.00441,0,0,1,.63-2.17l24.88-20.27A2.0076,2.0076,0,0,0,109.65,50.86Z"/>
      <!-- Index number -->
      <text x="64" y="76" text-anchor="middle" stroke="#FFE300" stroke-width="1"  style="fill: #333333; font-weight: 600; font-size: 28px; font-family: Arial;">
        ${index}
      </text>
    </svg>
  </div>
`;

  return L.divIcon({
    html: iconHtml,
    className: 'no-pointer-events-icon', // Important to disable default Leaflet icon styling
    iconSize: [0, 0],
    iconAnchor: [25, 25]
  });
};




export const Map = () => {
  const {
    savedHomes,
    mapCenter,
    filteredBusinesses,
    keywords,
    hoveredKeyword,
    heatmapOn,
    businessMarkersVisible,
    selectedBusiness,
    selectedHome,
    hoveredBusiness,
    cursorSearchRadius,
    cursorSearchMode,
    keywordFetchStatus,
    homeFetchStatus,
    userScans,
    mapZoom,
    clusters,
    recommendedAreasOn,
    clusterHullCentroids,
    userSearchBusinesses,
    unsavedHome,

  } = useGlobalStore((state) => ({
    savedHomes: state.savedHomes,
    mapCenter: state.mapCenter,
    filteredBusinesses: state.filteredAreaBusinesses,
    keywords: state.keywords,
    heatmapOn: state.heatmapOn,
    businessMarkersVisible: state.businessMarkersVisible,
    hoveredKeyword: state.hoveredKeyword,
    cursorSearchRadius: state.cursorSearchRadius,
    selectedBusiness: state.selectedBusiness,
    selectedHome: state.selectedHome,
    hoveredBusiness: state.hoveredBusiness,
    cursorSearchMode: state.cursorSearchMode,
    keywordFetchStatus: state.keywordFetchStatus,
    homeFetchStatus: state.homeFetchStatus,
    mapZoom: state.mapZoom,
    userScans: state.userScans,
    clusters: state.clusters,
    recommendedAreasOn: state.recommendedAreasOn,
    clusterHullCentroids: state.clusterHullCentroids,
    userSearchBusinesses: state.userSearchBusinesses,
    unsavedHome: state.unsavedHome,
  }))

  const [mousePosition, setMousePosition] = useState<[number, number] | null>(null)
  
  const [hoveredCluster, setHoveredCluster] = useState<Cluster | null>(null);
const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
const popupDelay = 500; // delay in milliseconds
let hoverTimeout = useRef<number| null>(null);


const keywordsWithBusinesses = useMemo(()=> keywords.filter((keyword) =>
hoveredCluster?.businesses.some((bis) => bis.types.includes(keyword.keyword))
),[hoveredCluster, keywords])

useEffect(() => {
  return () => {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
  };
}, []);

  const userScansNotEnough = userScans && userScans < keywords.length


  const disableCircle =
    homeFetchStatus === FetchStatus.FETCHING ||
    keywordFetchStatus === FetchStatus.FETCHING ||
    keywords.length === 0 ||
    userScansNotEnough ||
    !keywords.some((keyword) => !keyword.perm)



  const sortedBusinesses = useMemo(() => {
    if (!hoveredKeyword) return filteredBusinesses

    return [...filteredBusinesses].sort((a, b) => {
      // Add your sorting condition here
      // For example, if you want businesses with a certain keyword to appear on top:
      if (a.types.includes( hoveredKeyword?.keyword) && !b.types.includes(hoveredKeyword.keyword)) {
        return 1
      } else if (b.types.includes(hoveredKeyword.keyword) && !a.types.includes(hoveredKeyword.keyword)) {
        return -1
      } else {
        return 0
      }
    })
  }, [filteredBusinesses, hoveredKeyword])


  const businessMarkers = useMemo(() => {
    
   
    return [...sortedBusinesses, ...userSearchBusinesses].map((business, index) => {
      const { latitude, longitude } = business.location

      if (!latitude || !longitude) return null


      const businessTypes = business.userSearched ? business.types.filter(t => keywords.find(keyword => !keyword.perm && keyword.keyword === t)): business.types 

      const keyword = keywords.reverse().find((keyword) => businessTypes.includes(keyword.keyword))


      if (!businessMarkersVisible && keyword?.perm && !business.userSearched) return null

      const outerCircleStrokeColor =
      (hoveredKeyword?.keyword && business.types.includes(hoveredKeyword?.keyword)) || business.id === hoveredBusiness?.id
          ? 'SteelBlue'
          : business === selectedBusiness
          ? 'black'
          : 'white'

      const strokeWidth = 2

      const homeIcon = L.divIcon({
        className: 'my-custom-pin',
        iconAnchor: [0, 24],
        popupAnchor: [5, -26],

        html: `
          <svg style="position: absolute; top: -20px; right: -10px; width: 32px; height: 65px;" viewBox="0 0 60 60">
          <circle cx="27" cy="27" r="27" stroke="${outerCircleStrokeColor}" stroke-width="${strokeWidth}" fill="${keyword?.color}" />
            <circle cx="27" cy="27" r="17" fill="white" />
            <text x="45%" y="48%" dominant-baseline="middle" text-anchor="middle" fill="black" style="font-size: 16px;">${business.rating ? business.rating : ''}</text>
          </svg>`,
      })

      return (
        <Marker
          icon={homeIcon}
          key={`${index}-${hoveredKeyword?.keyword}`}
          zIndexOffset={index}
          position={[latitude, longitude]}
          eventHandlers={{
            click: () => {
              onBusinessClick(business)
            },
            mouseover: (e) => {
              e.target.openPopup()
            },
            mouseout: (e) => {
              e.target.closePopup()
            },
          }}
        >
          <Popup closeButton={false}>
            {business.displayName.text} <br />
          </Popup>
        </Marker>
      )
    })
  }, [sortedBusinesses, userSearchBusinesses, keywords, businessMarkersVisible,  hoveredKeyword?.keyword, hoveredBusiness?.id, selectedBusiness])

  const mapRef = useRef<L.Map | null>(null)

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(mapCenter, mapZoom)
    }
  }, [mapCenter, mapZoom])

  const MapEvents = () => {
    const map = useMap()

    useMapEvents({
      mousemove: (e) => {
        if (cursorSearchMode) {
          setMousePosition([e.latlng.lat, e.latlng.lng])
        }
      },
      mouseout: () => {
        setMousePosition(null)
      },
      click: (e: LeafletMouseEvent) => {
        if (cursorSearchMode && !disableCircle) {
          onClickSearch(e.latlng.lat, e.latlng.lng)

          return
        }

        // if option or alt is not held
        if (!e.originalEvent.altKey && !e.originalEvent.ctrlKey) {
          onClickAddUnsavedHome(e.latlng.lat, e.latlng.lng)
        }
        
      },
      zoomend: () => {
        const zoomLevel = map.getZoom()

        if (zoomLevel >= 17) {
          const newRadius = 1000 / 2
          setCursorSearchRadius(newRadius)
        } else {
          setCursorSearchRadius(1000)
        }
      },
    })

    return null
  }

  return (
    <MapContainer
      ref={(mapInstance) => {
        mapRef.current = mapInstance
      }}
      id="mapContainer"
      center={mapCenter}
      boxZoom={false}
      zoomControl={false}
      zoom={12}
      scrollWheelZoom
      style={{
        zIndex: 1,
        height: '100vh',
        width: '100%',
        cursor: 'pointer'
      }}
    >
      {recommendedAreasOn && clusterHullCentroids?.map((center, index) => {
  const starIcon = createStarIcon(index + 1);
  return (
    <Marker key={index + 1} position={center as LatLngTuple} icon={starIcon} />
  );
})}


      {businessMarkers}
      {savedHomes.map((home, index) => (
        <HomeMapMarkerIcon key={index} home={home} selectedHome={selectedHome} />
      ))}
      {
        unsavedHome && (
          <HomeMapMarkerIcon key={unsavedHome.id} home={unsavedHome} selectedHome={selectedHome} />
        )
      }
      
      {heatmapOn && (
        <HeatmapLayer
          zoom={1}
          // fitBoundsOnLoad
          // fitBoundsOnUpdate
          points={filteredBusinesses}
          longitudeExtractor={(b: Business) => b.location.longitude}
          latitudeExtractor={(b: Business) => b.location.latitude}
          intensityExtractor={(b: Business) => 1}
        />
      )}

      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <ZoomControl position="bottomright" />


      {mousePosition && cursorSearchMode && (
        <Circle
          key={disableCircle ? 'disabled' : 'enabled'}
          center={mousePosition}
          radius={cursorSearchRadius} // Set the radius as needed
          color={disableCircle ? '#A9A9A9' : 'SteelBlue'} // Set the color as needed
        />
      )}

{recommendedAreasOn && 
  clusters?.map((cluster, index) => (
    <Polygon
      key={index}
      positions={cluster.points} // Convert each point to [lat, lng]
      className="area-polygon"
      eventHandlers={{
  
        mouseover: (e: LeafletMouseEvent) => {
          if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
          hoverTimeout.current = setTimeout(() => {
            setHoveredCluster(cluster);
          }, popupDelay) as unknown as number;
        },
       
        mousemove: (e: LeafletMouseEvent) => {
          // Clear any existing timeout to reset the delay
          if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
          }

          const numberOfKeywords = keywords.filter((keyword) =>
          cluster?.businesses.some((bis) => bis.types.includes(keyword.keyword))).length - 4;


          const offsetX = 310; // Adjust this value to position the corner of the popup over the cursor
          const offsetY = 190 + (22 * numberOfKeywords ); // Adjust this value as well
          // Set a new timeout

        
        
          hoverTimeout.current = setTimeout(() => {
            setHoveredCluster(cluster); // Show the popup after the mouse has stopped moving for the delay period
            setPopupPosition({ 
              top: e.originalEvent.clientY - offsetY, 
              left: e.originalEvent.clientX - offsetX 
            });
            // setPopupContent(`Cluster ${index + 1}`);
          }, popupDelay) as unknown as number;
        
          // Optionally, you can hide the popup immediately when the mouse starts moving
          setHoveredCluster(null);
        },
        
        mouseout: () => {
          if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
            hoverTimeout.current = null;
          }
          setHoveredCluster(null);
        },
        click: (e) => {
          onClickAddUnsavedHome(e.latlng.lat, e.latlng.lng);
        }
      }}
        


    />
  ))
}


{hoveredCluster && (
  <AreaPopUp keywordsWithBusinesses={keywordsWithBusinesses} hoveredCluster={hoveredCluster} style={{ position: 'absolute', top: popupPosition.top, left: popupPosition.left, zIndex: 1000 }} />
)}
      <MapEvents />
    </MapContainer>
  )
}
