import { styled } from 'stitches.config'

export const KeywordCircle = styled('div', {
  borderRadius: 15,
  width: 15,
  height: 15,
  border: '1px solid white',
  marginRight: 8,
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    width: 6,
    height: 6,
    borderRadius: 4,
    background: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})
