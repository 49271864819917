import { api } from 'api'
import axios from 'axios'
import { uniqueId } from 'lodash'
import { homeColors, keywordColors } from 'shared/constants'
import { Business, FetchStatus, Home, Keyword, SearchLocation } from 'shared/types'
import { useGlobalStore } from 'store/global/store'
import { processBusinesses, rankHomes } from 'utility'


let homeIdCounter = 0;

export const addSearchedHome = (searchLocation?: SearchLocation) => {
  const { savedHomes: homes,keywords, filteredAreaBusinesses, userSearchBusinesses } = useGlobalStore.getState()


  
  if (!searchLocation) {
    return
  }

  let isAlreadyInComparison = false

  for (const location of homes) {
    if (location.geometry === searchLocation.geometry) {
      isAlreadyInComparison = true
      break
    }
  }

  if (isAlreadyInComparison) {
    return
  }


  const newlyAddedHome = {
    ...searchLocation,
    id: uniqueId(), 
    color: homeColors[homeIdCounter % homeColors.length], // Use modulo for color assignment
  };

  homeIdCounter++; // Increment the counter


 

  const mapCenter = [searchLocation.geometry.lat, searchLocation.geometry.lng] as [number, number]

  const rankedHomes = rankHomes([newlyAddedHome, ...homes], keywords, [...filteredAreaBusinesses, ...userSearchBusinesses])



  useGlobalStore.setState({
    savedHomes: rankedHomes,
    newlyAddedHome: newlyAddedHome,
    mapCenter,
  })
}

export const onHomeClick = (location: Home, centerMap?: boolean) => {
  const mapCenter = [location.geometry.lat, location.geometry.lng] as [number, number]
  useGlobalStore.setState({
    mapCenter: centerMap ? mapCenter : useGlobalStore.getState().mapCenter,
    selectedHome: location,
    drawerWidth: 450,
    selectedBusiness: undefined,
  })
}

export const deleteHome = (deleteableHome: Home) => {
  const { savedHomes: comparisonLocations, keywords, filteredAreaBusinesses, userSearchBusinesses} = useGlobalStore.getState()

  const newHomes = comparisonLocations.filter((home) => deleteableHome.id !== home.id)
  const rankedHomes = rankHomes(newHomes, keywords, [...filteredAreaBusinesses, ...userSearchBusinesses])

  setDrawerWidth(0)

    return useGlobalStore.setState({
      savedHomes: rankedHomes,
      selectedHome: undefined,
      drawerWidth: 0,
    })

}

export const setIsSignedIn = (isSignedIn: boolean) => {
  useGlobalStore.setState({
    isSignedIn,
  })
}

export const toggleKeyword = (keyword: string) => {
  const { keywords } = useGlobalStore.getState()

  const newKeywords = keywords.map((k) => {
    if (k.keyword === keyword) {
      return {
        ...k,
        active: !k.active,
      }
    }

    return k
  })

  useGlobalStore.setState({
    keywords: newKeywords,
  })
}

export const deleteKeyword = (keyword: string) => {
  const { keywords, selectedBusiness, userSearchBusinesses, savedHomes, filteredAreaBusinesses } = useGlobalStore.getState()

  const newKeywords = keywords.filter((k) => k.keyword !== keyword)

  const newBusinesses = userSearchBusinesses.filter((business) => !business.types.includes(keyword))

  let newSelectedBusiness = selectedBusiness
  let newDrawerWidth = selectedBusiness?.types.includes(keyword) ? 0 : useGlobalStore.getState().drawerWidth


  if (selectedBusiness?.types.includes(keyword)) {
    newSelectedBusiness = undefined
  }

  const rankedHomes = rankHomes(savedHomes, keywords, [...filteredAreaBusinesses, ...newBusinesses])

  useGlobalStore.setState({
    keywords: newKeywords,
    userSearchBusinesses: newBusinesses,
    selectedBusiness: newSelectedBusiness,
    drawerWidth: newDrawerWidth,
    savedHomes: rankedHomes,
  })
}

const keywordColorMap = new Map();

export const addKeyword = (newKeywords: string[]) => {
  const { keywords } = useGlobalStore.getState();

  const keywordWithStatus = newKeywords.map((keyword) => {
    // Check if the color is already assigned
    if (!keywordColorMap.has(keyword)) {
      keywordColorMap.set(keyword, keywordColors[keywordColorMap.size % keywordColors.length]);
    }

    return {
      keyword,
      active: true,
      color: keywordColorMap.get(keyword),
    };
  });

  const newKeywordsWithStatus = [...keywords, ...keywordWithStatus];

  useGlobalStore.setState({
    keywords: newKeywordsWithStatus,
    newKeywords,
  });
};

export const setBusinessMarkersVisible = (businessMarkersVisible: boolean) => {
  useGlobalStore.setState({
    businessMarkersVisible,
  })
}

export const setHeatmapOn = (heatmapOn: boolean) => {
  useGlobalStore.setState({
    heatmapOn,
  })
}

export const setRecommendedAreasOn = (recommendedon: boolean) => {
  useGlobalStore.setState({
    recommendedAreasOn:recommendedon
  })
}




export const setFilterRating = (filterRating?: number | null) => {
  if (filterRating === null) {
    return
  }

  useGlobalStore.setState({
    filterRating,
  })
}

export const setMinimumReviews = (reviews?: number | null) => {
  if (reviews === null) {
    return
  }

  useGlobalStore.setState({
    minimumNumberOfRatings: reviews,
  })
}



export const setHoveredKeyword = (hoveredKeyword?: Keyword) => {
  if (!hoveredKeyword) {
    useGlobalStore.setState({
      hoveredKeyword: undefined,
    })
    return
  }

  useGlobalStore.setState({
    hoveredKeyword: hoveredKeyword,
  })
}

export const setHoveredBusiness = (hoveredBusiness?: Business) => {
  if (!hoveredBusiness) {
    useGlobalStore.setState({
      hoveredBusiness: undefined,
    })
    return
  }

  useGlobalStore.setState({
    hoveredBusiness: hoveredBusiness,
  })
}

export const onBusinessClick = (selectedBusiness?: any) => {
  useGlobalStore.setState({
    selectedBusiness,
    selectedHome: undefined,
    drawerWidth: 450,
  })
}

export const setDrawerWidth = (width?: number) => {
  const {drawerWidth, unsavedHome, selectedHome} = useGlobalStore.getState()

  const newWidth = drawerWidth === 450 ? 0 : 450

  useGlobalStore.setState({
    drawerWidth: typeof width === 'number' ? width : newWidth,
    unsavedHome: newWidth === 0 ? undefined : unsavedHome,
    selectedHome: selectedHome?.id === unsavedHome?.id ? undefined : selectedHome,

  })
}


export const setSelectedHome = (selectedHome?: Home) => {
  useGlobalStore.setState({
    selectedHome,
    selectedBusiness: undefined,
  })
}

export const onClickSearch = async (lat: number, lng: number) => {
  const { cursorSearchRadius, savedHomes, userSearchBusinesses, cursorSearchMode,filteredAreaBusinesses, keywords, homeFetchStatus, keywordFetchStatus,  userId } =
    useGlobalStore.getState()

  if (
    !cursorSearchMode ||
    !keywords.length ||
    homeFetchStatus === FetchStatus.FETCHING ||
    keywordFetchStatus === FetchStatus.FETCHING
  ) {
    return
  }

  useGlobalStore.setState({
    keywordFetchStatus: FetchStatus.FETCHING,
  })

  const activeAndNotPermKeywords = keywords.filter((k) => k.active && !k.perm).map((k) => k.keyword)

  if (!userId) {
    return
  }

  try {
    const { data: newBusinesses } = await api.postSearch({
      userId,
      keywords: activeAndNotPermKeywords,
      radius: cursorSearchRadius,
      coordinates: [
        {
          latitude: lat,
          longitude: lng,
        },
      ],
    })


    const newBusinessesWithUserFlag = newBusinesses.map(b => ({
      ...b,
      userSearched: true
    })
    )

    const newKeywordNonDuplicateCount = newBusinesses.filter(b => !userSearchBusinesses.some(us => us.id === b.id)).length


    const deduplicatedBusinesses = newBusinessesWithUserFlag.filter((b) => !userSearchBusinesses.some((us) => us.id === b.id))

    const allUserSearchBusinesses = [...userSearchBusinesses, ...deduplicatedBusinesses]



  const rankedHomes = rankHomes(savedHomes, keywords, [...filteredAreaBusinesses, ...allUserSearchBusinesses])


    useGlobalStore.setState({
      userSearchBusinesses: allUserSearchBusinesses,
      keywordFetchStatus: FetchStatus.SUCCESS,
      newKeywordBusinessCount: newBusinesses.length,
      newKeywordNonDuplicateCount: newKeywordNonDuplicateCount,
      savedHomes: rankedHomes,
    })

    const { data: userScans } = await api.getUserScans({ userId: userId })

    useGlobalStore.setState({
      userScans: userScans.scans,
    })
  } catch (err) {
    console.error('Error during search:', err)
    useGlobalStore.setState({
      keywordFetchStatus: FetchStatus.ERROR,
    })
  }
}

export const onClickAddUnsavedHome = async (lat: number, lng: number) => {
  const { savedHomes } = useGlobalStore.getState()





  try {
    const { data: homeDatas } = await api.reverseGeocodeGet(lat, lng)

    const homeData = homeDatas[0]

    let isAlreadyInComparison = false

    for (const location of savedHomes) {
      if (location.geometry === homeData.geometry) {
        isAlreadyInComparison = true
        break
      }
    }

    if (isAlreadyInComparison) {
      return
    }

    const newlyAddedHome = {
      ...homeData,
      color: '#fff',
      id: uniqueId()
    }



    useGlobalStore.setState({
      unsavedHome: newlyAddedHome,
      newlyAddedHome: newlyAddedHome,
    selectedHome: newlyAddedHome,
    drawerWidth: 450,
    selectedBusiness: undefined,
    })
  } catch (err) {
    useGlobalStore.setState({ homeFetchStatus: FetchStatus.ERROR })
  }
}

export const setCursorSearchRadius = (radius: number) => {
  useGlobalStore.setState({
    cursorSearchRadius: radius,
  })
}

export const setMapLocation = (newMapCenter: [number, number]) => {
  useGlobalStore.setState({
    mapCenter: newMapCenter,
    mapZoom: 14,
  })
}



export const setSelectedCity = async (cityId: number) => {
  const { keywords, selectedCity, getCityCancelToken, cities } = useGlobalStore.getState()

  useGlobalStore.setState({ cityDataFetchStatus: FetchStatus.FETCHING })

  getCityCancelToken?.cancel()

  const source = axios.CancelToken.source();

  const city = cities.find((city) => city.id === cityId)

  if (!city || selectedCity.id === city.id) {
    return
  }

  useGlobalStore.setState({
    selectedCity: city,
    mapZoom: 13,
    mapCenter: [parseFloat(city.latitude), parseFloat(city.longitude)] as [number, number],
    selectedHome: undefined,
    selectedBusiness: undefined,
    userSearchBusinesses: [],
    unsavedHome: undefined,
    savedHomes: [],
    drawerWidth: 0,
    getCityCancelToken: source
  })

  const { data: newBusinesses } = await api.businessCountryCodeCityIdGet(city.country_code, city.id, { cancelToken: source.token })

  const { deduplicatedBusinesses, filteredBusinesses } = processBusinesses(newBusinesses, keywords)

  useGlobalStore.setState({
    businesses: deduplicatedBusinesses,
    filteredAreaBusinesses: filteredBusinesses,
    newHomeBusinessCount: newBusinesses.length,
    cityDataFetchStatus: FetchStatus.SUCCESS,
  })
}

export const onSaveHomeClick = async (home: Home) => {
  const { savedHomes, keywords, filteredAreaBusinesses, userSearchBusinesses } = useGlobalStore.getState();

  const newlyAddedHome = {
    ...home,
    id: uniqueId(),
    color: homeColors[homeIdCounter % homeColors.length], // Use modulo for color assignment
  };

  homeIdCounter++; // Increment the counter

  const rankedHomes = rankHomes([newlyAddedHome, ...savedHomes], keywords, [...filteredAreaBusinesses, ...userSearchBusinesses])


  useGlobalStore.setState({
    savedHomes: rankedHomes,
    unsavedHome: undefined,
    selectedHome:  rankedHomes.find((h) => h.id === newlyAddedHome.id),

  })
}
