import { EnvironmentOutlined, GoogleOutlined, LinkOutlined, PhoneOutlined, StarFilled } from '@ant-design/icons'
import { Divider, Typography } from 'antd'
import { useGlobalStore } from 'store/global'

import { IconLink, IconText } from 'components'

// const ImageComponent = ({ src, alt }: { src: string; alt: string }) => (
//   <div style={{ width: '100%', height: 0, paddingBottom: '75%', position: 'relative' }}>
//     <img src={src} alt={alt} style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} />
//   </div>
// )


export const calculatePriceLevel = (priceLevel: string) => {

  if(priceLevel === 'PRICE_LEVEL_MODERATE') return '$$'

  if(priceLevel === 'PRICE_LEVEL_INEXPENSIVE') return '$'

  if(priceLevel === 'PRICE_LEVEL_EXPENSIVE') return '$$$'

  if(priceLevel === 'PRICE_LEVEL_VERY_EXPENSIVE') return '$$$$'

}


export const BusinessDetails = () => {
  const { selectedBusiness, drawerWidth } = useGlobalStore((state) => ({
    selectedBusiness: state.selectedBusiness,
    drawerWidth: state.drawerWidth,
  }))

  if (!selectedBusiness) return null

  const { displayName, primaryTypeDisplayName, googleMapsUri, internationalPhoneNumber, websiteUri, priceLevel, shortFormattedAddress, rating, userRatingCount, id } = selectedBusiness




  return (
    <div
      style={{
        minWidth: drawerWidth,
        width: drawerWidth,
      }}
    >
      {/* {photos && <ImageComponent src={photos[0]} alt={name} />} */}
      <div style={{ padding: 24, paddingTop: 0 }}>
        <Typography.Title style={{ color: 'rgb(40,17,26)', marginTop: 16 }} level={3}>{displayName.text}</Typography.Title>
        {primaryTypeDisplayName && <Typography.Text type="secondary">{primaryTypeDisplayName.text}{' - '}</Typography.Text>}
        <Typography.Text type="secondary"> {rating ? rating : ''}</Typography.Text>{' '} { rating ? <StarFilled style={{ color: '#FBBC03' }} /> : ''} {' '} 
        <Typography.Text type="secondary">
          {rating && userRatingCount ? `(${userRatingCount})`: ''}{priceLevel ? ` • ${calculatePriceLevel(priceLevel)}` : ''}
        </Typography.Text>
    
      
        <Divider />
        <IconText
          text={shortFormattedAddress}
          icon={<EnvironmentOutlined style={{ color: '#1890ff', fontSize: 18, margin: 8 }} />}
        />
  
        <IconLink
          text="maps.google.com"
          icon={<GoogleOutlined style={{ color: '#1890ff', fontSize: 18, margin: 8 }} />}
          url={googleMapsUri?  googleMapsUri : `https://www.google.com/maps/place/?q=place_id:${id}`}
        />
          <IconText
          text={internationalPhoneNumber}
          icon={<PhoneOutlined style={{ color: '#1890ff', fontSize: 18, margin: 8 }} />}
        />
         {websiteUri && <IconLink
          url={websiteUri}
          text={new URL(websiteUri).hostname}
          icon={<LinkOutlined style={{ color: '#1890ff', fontSize: 18, margin: 8 }} />}
        />}

    
        {/* {open_hours && <OpeningHours hours={open_hours} />} */}
        <br />
      </div>
    </div>
  )
}
