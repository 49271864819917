import { AutoComplete, Form, Input } from 'antd'
import { BaseOptionType, DefaultOptionType } from 'antd/es/select'
import { api } from 'api'
import { debounce } from 'lodash'
import { SelectHandler } from 'rc-select/lib/Select'
import { useMemo, useState } from 'react'
import { SearchLocation } from 'shared/types'
import { styled } from 'stitches.config'
import { setMapLocation } from 'store/global'

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px',
  backgroundColor: '#F0F0F0',
})

export const GlobalSearch = () => {
  const [geocodes, setGeocodes] = useState<SearchLocation[]>([])
  const [inputValue, setInputValue] = useState('')

  const options = useMemo(
    () =>
      geocodes.map((result) => ({
        value: result.id.toString(),
        label: result.formatted,
      })),
    [geocodes],
  )

  const handleSearch = async (term: string) => {
    try {
      if (!term || (term.length === 2 && term[1] === ' ')) {
        return
      }

      const response = await api.forwardGeocodeGet(term)

      setGeocodes(response.data)
    } catch (error) {
      console.error('Error during search:', error)
    }
  }

  const handleSelect: SelectHandler<unknown, DefaultOptionType | BaseOptionType> = (
    value: unknown,
    option: DefaultOptionType | BaseOptionType,
  ) => {
    if (value === undefined) {
      return
    }

    const found = geocodes.find((result) => result.id === parseInt(value as string))

    if (found) {
      setMapLocation([found.geometry.lat, found.geometry.lng])
      setInputValue(found.formatted)
    }
  }

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [])

  return (
    <Wrapper>
      <Form>
        <Form.Item>
          <AutoComplete
            options={options}
            value={inputValue}
            onSelect={handleSelect}
            placeholder="Go anywhere in the word"
            onSearch={(value) => {
              setInputValue(value)
              debouncedHandleSearch(value)
            }}
          >
            <Input size="large" />
          </AutoComplete>
        </Form.Item>
      </Form>
    </Wrapper>
  )
}
