import { api } from 'api'
import { FetchStatus } from 'shared/types'
import { useGlobalStore } from 'store/global/store'
import { processBusinesses } from 'utility'

export const useFetchHomesBusinessData = () => {
  const { keywords, selectedCity } = useGlobalStore.getState()

  const fetchInitialBusinessData = async () => {
    useGlobalStore.setState({ cityDataFetchStatus: FetchStatus.FETCHING })

    if (!selectedCity) {
      return
    }

    const { data: newBusinesses } = await api.businessCountryCodeCityIdGet(selectedCity.country_code, selectedCity.id)

    const { deduplicatedBusinesses, filteredBusinesses } = processBusinesses(newBusinesses, keywords)

    useGlobalStore.setState({
      businesses: deduplicatedBusinesses,
      filteredAreaBusinesses: filteredBusinesses,
      newHomeBusinessCount: newBusinesses.length,
      cityDataFetchStatus: FetchStatus.SUCCESS,
    })
  }

  return { fetchHomesBusinessData: fetchInitialBusinessData }
}
