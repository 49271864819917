import { CloseOutlined } from '@ant-design/icons'
import { AutoComplete as AutoCompleteRoot, Form, Input } from 'antd'
import { BaseOptionType, DefaultOptionType } from 'antd/es/select'
import { debounce } from 'lodash'
import { SelectHandler } from 'rc-select/lib/Select'
import { useMemo, useState } from 'react'
import { FetchStatus, SearchLocation } from 'shared/types'
import { styled } from 'stitches.config'
import { addSearchedHome, useGlobalStore } from 'store/global'
import { api } from '../../../api'


const AutoComplete = styled(AutoCompleteRoot, {
  width: '100%',
  height: 30,
  backgroundColor: 'white',
  '& .ant-select-selection-placeholder': {
    fontSize: 14,

    display: 'flex',
    alignItems: 'center',
  },


})


export const Search = () => {
  const {  homeFetchStatus } = useGlobalStore((state) => ({
    homeFetchStatus: state.homeFetchStatus,
    newHomeBusinessCount: state.newHomeBusinessCount,
  }))
  const [geocodes, setGeocodes] = useState<SearchLocation[]>([])
  const [inputValue, setInputValue] = useState('')

  const options = useMemo(
    () =>
      geocodes.map((result) => ({
        value: result.id.toString(),
        label: result.formatted,
      })),
    [geocodes],
  )

  const handleSearch = async (term: string) => {
    try {
      if (!term || (term.length === 2 && term[1] === ' ')) {
        return
      }

      const response = await api.forwardGeocodeGet(term)

      setGeocodes(response.data)
    } catch (error) {
      console.error('Error during search:', error)
    }
  }

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [])



  const handleSelect: SelectHandler<unknown, DefaultOptionType | BaseOptionType> = (
    value: unknown,
    option: DefaultOptionType | BaseOptionType,
  ) => {
    if (value === undefined) {
      return
    }

    const found = geocodes.find((result) => result.id === parseInt(value as string))

    if (found) {
      setInputValue(found.formatted)
      addSearchedHome(found)
    }

    addSearchedHome(found)
  }

  return (
    <>
      <Form>
        <Form.Item>
          <AutoComplete
            disabled={homeFetchStatus === FetchStatus.FETCHING}
            options={options}
            value={inputValue}
            onSelect={handleSelect}
            placeholder="Search any address"
            onSearch={(value) => {
              setInputValue(value)
              debouncedHandleSearch(value)
            }}
          >
            <Input
              size="middle"
              disabled={homeFetchStatus === FetchStatus.FETCHING}
            />
          </AutoComplete>
        </Form.Item>
      </Form>
    </>
  )
}
