export const keywordColors = [
  '#F27299',
  '#84AFC1',
  '#9FCEA6',
  '#F0B39E',
  '#B59AE0',
  '#F3CB8A',
  '#8ACBDB',
  '#ABCAEE',
  '#FFC7B0',
  '#E1C2AB',
  '#D1A3A4',
  '#A9C6C2',
  '#A1D6E2',
  '#1995AD',
  '#D3E2E6',
  '#F9D423',
  '#FC913A',
  '#FF4E50',
  '#E5FCC2',
  '#9DE0AD',
  '#45ADA8',
  '#547980',
  '#594F4F',
  '#FE4365',
]

export const homeColors = [
  '#FF6384',
  '#2ECC71',
  '#FFAB36',
  '#6C79DB',
  '#FFB3BA',
  '#62D8EF',
  '#FFCA80',
  '#FF6F61',
  '#84CEEB',
  '#FFD700',
]
