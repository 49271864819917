import { Typography } from 'antd'
import { Homes } from './Homes'
import { Search } from './Search'

export const SearchHomes = () => {
  return (
    <>
      <Search />


      <Homes />
    </>
  )
}
