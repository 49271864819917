import { Typography } from 'antd'

interface IconLinkProps {
  text: string
  icon: React.ReactNode
  url: string
}

export const IconLink: React.FC<IconLinkProps> = ({ text, icon, url }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ marginRight: 16 }}>{icon}</div>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Typography.Text>{text}</Typography.Text>
    </a>
  </div>
)
