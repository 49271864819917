import { City } from 'api/gen'
import { CancelTokenSource } from 'axios'
import { keywordColors } from 'shared/constants'
import { Business, Cluster, FetchStatus, Home, Keyword } from 'shared/types'

import { StateCreator, create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export type GlobalStore = {
  cityDataFetchStatus?: FetchStatus
  savedHomes: Home[]
  unsavedHome?: Home
  mapCenter: [number, number]
  isSignedIn: boolean
  keywords: Keyword[]
  newKeywords: string[]
  getCityCancelToken?: CancelTokenSource
  businesses: Business[]
  newlyAddedHome?: Home
  userId?: string
  newHomeBusinessCount?: number
  newKeywordBusinessCount?: number
  newKeywordNonDuplicateCount?: number
  homeFetchStatus?: FetchStatus
  keywordFetchStatus?: FetchStatus
  heatmapOn: boolean
  recommendedAreasOn: boolean
  minimumNumberOfRatings: number
  businessMarkersVisible: boolean
  filterRating: number
  filteredAreaBusinesses: Business[]
  userSearchBusinesses: Business[]
  pinAddressMode: boolean
  hoveredKeyword?: Keyword
  selectedBusiness?: Business
  selectedHome?: Home
  drawerWidth: number
  hoveredBusiness?: Business
  cursorSearchRadius: number
  cursorSearchMode: boolean
  selectedCity: City
  userScans?: number
  mapZoom: number
  clusters: Cluster[]
  clusterHullCentroids?: number[][]
  cities: City[]
}

const center: [number, number] = [37.9838, 23.8]

const Athens = {
  id: 900000000,
  name: 'Canggu',
  state_id: 1826,
  state_code: 'BA',
  state_name: 'Bali',
  country_id: 102,
  country_code: 'ID',
  country_name: 'Indonesia',
  latitude: '-8.6478',
  longitude: '115.1385',
  wikiDataId: 'Q18970',
}

const globalStore: StateCreator<GlobalStore> = (set, getState) => ({
  cursorSearchRadius: 1000,
  cursorSearchMode: false,
  pinAddressMode: false,
  minimumNumberOfRatings: 5,
  clusters: [],
  selectedCity: Athens,
  savedHomes: [],
  filterRating: 4.3,
  recommendedAreasOn: true,
  cities: [Athens],
  businesses: [],
  mapCenter: center,
  isSignedIn: false,
  filteredAreaBusinesses: [],
  userSearchBusinesses: [],
  mapZoom: 12,
  newKeywords: [],
  heatmapOn: false,
  businessMarkersVisible: false,
  drawerWidth: 0,
  keywords: [
    { keyword: 'gym', active: false, color: keywordColors[0], perm: true, desc: 'gyms', alt: 'gym' },
    {
      keyword: 'cowork',
      active: false,
      color: keywordColors[1],
      perm: true,
      desc: 'co-working spaces',
      alt: 'co-working',
    },
    {
      keyword: 'restaurant',
      active: true,
      color: keywordColors[2],
      perm: true,
      desc: 'restaurants',
      alt: 'restaurant',
    },
    { keyword: 'cafe', active: true, color: keywordColors[3], desc: 'cafes or coffee places', alt: 'cafe', perm: true },
    {
      keyword: 'night_club',
      active: false,
      color: keywordColors[5],
      perm: true,
      alt: 'club',
      desc: 'clubs',
    },
    {
      keyword: 'bar',
      active: false,
      color: keywordColors[6],
      perm: true,
      alt: 'bar',
      desc: 'bars',
    },
    {
      keyword: 'grocery_store',
      active: false,
      color: keywordColors[7],
      perm: true,
      alt: 'groceries',
      desc: 'grocery stores',
    },
  ],
})

export const useGlobalStore = create(subscribeWithSelector(globalStore))
