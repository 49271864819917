import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd'
import { globalCss, styled } from 'stitches.config'
import { setDrawerWidth, useGlobalStore } from 'store/global'
import { BusinessDetails } from './BusinessDetails'
import { HomeDetails } from 'features/RightSidebar/HomeDetails'
import { TfiClose } from 'react-icons/tfi'

const GlobalStyle = globalCss({
  '.ant-drawer-right': {
    position: 'absolute',
    // top: 64,
  },

  '.ant-drawer-content-wrapper': {
    border: '1px solid lightgray',
    boxShadow: '-10px 0 10px -10px rgba(0, 0, 0, 0.1) !important',
  },
})

const CloseButton = styled(Button, {
  zIndex: 10,
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  width: 36,
  padding: 0,
  paddingTop: 4,
  justifyContent: 'center',
  boxShadow: 'none',
  cursor: 'pointer',
  fontSize: 18 ,
  color: 'rgb(40,17,26)',

  // hover color
  '&:hover': {
    color: '#g',
  },
})

const CloseButtonContainer = styled('div', {

   padding: 0,
   paddingLeft: 0,
   height: 24,

})


const DrawerToggle = styled('div', {
  position: 'absolute',
  left: -30,
  zIndex: 10,
  top: '50%',
  width: 30,
  height: 60,
  borderRadius: '14px 0 0 14px',
  backgroundColor: '#f0f0f0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

export const RightSidebar = () => {
  GlobalStyle()
  const { selectedBusiness, selectedHome, drawerWidth } = useGlobalStore()

  return (
    <>
      <Drawer
        mask={false}
        closable={false}
        placement="right"
        open={true}
        width={drawerWidth}
        styles={{
          header: {
            backgroundColor: 'rgb(250, 250, 250)' 
          },
          body: {
            padding: 0,
            backgroundColor: 'rgb(250, 250, 250)' 
          },
        }}
      >
        <DrawerToggle
          className="drawer-toggle" 
          onClick={() => (selectedBusiness || selectedHome ? setDrawerWidth() : '')}
        >
          {drawerWidth === 0 ? (
            <CaretLeftOutlined
              style={{
                color: selectedBusiness || selectedHome ? 'initial' : 'gray',
              }}
            />
          ) : (
            <CaretRightOutlined />
          )}
        </DrawerToggle>
            <CloseButtonContainer>
        <CloseButton
          onClick={() => setDrawerWidth()} // Replace with appropriate function call
          
        >
          <TfiClose />
        </CloseButton>
        </CloseButtonContainer>

        
        {drawerWidth && selectedBusiness ? <BusinessDetails /> : null}
        {drawerWidth && selectedHome ? <HomeDetails /> : null}

      </Drawer>
    </>
  )
}
