import { styled } from "@stitches/react";
import { Select, Spin} from "antd";
import { City } from "api/gen";
import ReactCountryFlag from "react-country-flag";
import { FetchStatus } from "shared/types";
import { setSelectedCity, useGlobalStore } from "store/global";

const StyledSelectWrapper = styled('div', {
    '& .ant-select .ant-select-selector': {
    border: 'none',
    borderRadius: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    fontSize: '16px', // Match this font size with the input
    },

'& .ant-select .ant-select-selector .ant-select-selection-search-input': {
  fontSize: '16px', // Ensure this matches the font size above
},
'& .ant-select .ant-select-selector .ant-select-selection-item': {
  fontSize: '16px', // Match this font size with the input
},


  });

const SelectorWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
    border: '1px solid #d9d9d9',
})
  
export const CitySelect = () => {
    const { cityDataFetchStatus, selectedCity, cities } = useGlobalStore((state) => ({
        cityDataFetchStatus: state.cityDataFetchStatus,
        selectedCity: state.selectedCity,
        cities: state.cities,
    }))

return (
    <SelectorWrapper >
        <div style={{
            paddingLeft: 12,
            paddingRight: 12,
            width: 52,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
              {cityDataFetchStatus === FetchStatus.SUCCESS && <ReactCountryFlag countryCode={selectedCity?.country_code} 
               
               svg
               style={{
                   width: '2em',
                   height: '2em',
               }}
               title="Thailand"
               />}
      {cityDataFetchStatus === FetchStatus.FETCHING &&  <Spin 
    size="default"
    ></Spin>}
    </div>

        <StyledSelectWrapper>
<Select
showSearch
style={{ width: 300 }}
onChange={(value) => setSelectedCity(value)}
defaultValue={selectedCity.id}
size="large"
filterOption={(input, option) => {


   const combinedString =  option?.children?.join('').toLowerCase();

   if(!combinedString) return false;

   const lowerCaseSearchTerm = input.toLowerCase();



   return combinedString.includes(lowerCaseSearchTerm);
  }}
>
{cities.map((city: City) => (
    <Select.Option  key={city.id} value={city.id}>{city.name}, {city.country_name}</Select.Option>
))}
</Select>
</StyledSelectWrapper>
  
</SelectorWrapper>
);
};

