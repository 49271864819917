import { api } from 'api'
import { useGlobalStore } from 'store/global/store'

export const useFetchUserdata = () => {
  const fetchInitialUserData = async (id?: string) => {
    if (!id) {
      return
    }

    const { data: userScans } = await api.getUserScans({ userId: id })

    useGlobalStore.setState({
      userScans: userScans.scans,
    })
  }

  return { fetchUserData: fetchInitialUserData }
}
