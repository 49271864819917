import { Layout } from 'antd'
import { Map } from 'components'
import { CitySelect } from 'components/CitySelect/CitySelect'
import { LeftSidebar, RightSidebar } from 'features'

export const Page = () => {
  return (
    <Layout>
      <Layout>
        <LeftSidebar />
        <Layout.Content style={{ position: 'relative' }}>
          {/* <Legend /> */}
          <div style={{ position: 'absolute', top: 16, left: 16, zIndex: 10 }}>
            <CitySelect />
          </div>

          <Map />
        </Layout.Content>
        <RightSidebar />
      </Layout>
    </Layout>
  )
}

export default Page
