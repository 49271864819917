import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { ClerkProvider } from '@clerk/clerk-react'
import { globalStyles } from 'stitches.config'


globalStyles();

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl

const originalWarn = console.warn.bind(console.warn)

console.warn = (msg) => {
  if (msg.includes('Failed to parse source map')) {
    return
  }
  originalWarn(msg)
}

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <ClerkProvider publishableKey={clerkPubKey} 
   appearance={{
    elements: {
        footer: "hidden"
    }
}}>
    <App />
  </ClerkProvider>,
  // </React.StrictMode>,
)
