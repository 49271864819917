import { styled } from '@stitches/react';
import { Typography } from 'antd';
import { Cluster, Keyword } from 'shared/types';
import { useGlobalStore } from 'store/global';
import { getOrdinalSuffix } from 'utility';

const Container = styled('div', {
  position: 'absolute',
  border: '1px solid #d9d9d9',
  borderRadius: 8,
  zIndex: 10,
  backgroundColor: '#FAFAFA',
  left: 16,
  bottom: 16,
  padding: 16,
  minWidth: 200,
  width: 'fit-content',
  height: 'fit-content',
  fontSize: '12px !important',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})



interface AreaPopUpProps {
  style?: React.CSSProperties
  hoveredCluster: Cluster
  keywordsWithBusinesses: Keyword[]
}

export const AreaPopUp = ({hoveredCluster, keywordsWithBusinesses, ...props}: AreaPopUpProps) => {
  const {  rating, numberReviews} = useGlobalStore((state) => ({
    drawerWidth: state.drawerWidth,
    keywords: state.keywords,
    savedHomes: state.savedHomes,
    rating: state.filterRating,
    numberReviews: state.minimumNumberOfRatings,
  }))

 
  
  const text = hoveredCluster.rating < 6 ? `Area ranked ${getOrdinalSuffix(hoveredCluster.rating)} due to its density. It has:` : 'Area recommended because it has:'

  return (
  <Container {...props}>
    
    <Typography.Text>{text}     </Typography.Text>
    <div style={{display: 'flex', flexDirection: 'row'}}>
    <ul>
      { keywordsWithBusinesses.map((keyword, index) => {
        const number = hoveredCluster.businesses.filter(bis => bis.types.includes(keyword.keyword)).length
          return (
            <li key={index}> 
              <Typography.Text>
                {number} {number > 1  || keyword.keyword == 'groceries'? keyword.desc : keyword.keyword}
              </Typography.Text>
            </li>
          )
        })
      }
      </ul>
      </div>
      <Typography.Text >with a rating of {rating} or higher and at least {numberReviews} reviews.   </Typography.Text>
    </Container>
  )
}
