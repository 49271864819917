import { Col, Divider, InputNumber, Row, Slider, Switch, Typography } from 'antd'
import { KeywordItem } from 'components'
import { useMemo } from 'react'

import { styled } from 'stitches.config'
import {
  setBusinessMarkersVisible,
  setFilterRating,
  setMinimumReviews,
  setRecommendedAreasOn,
  toggleKeyword,
  useGlobalStore
} from 'store/global'

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2px 0px',
})

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

export const MapSettings = () => {
  const {
    minimumNumberOfRatings,
    businessMarkersVisible,
    filterRating,
    recommendedAreasOn,
    keywords,
  } = useGlobalStore((state) => ({
    heatmapOn: state.heatmapOn,
    selectedCityId: state.selectedCity,
    businessMarkersVisible: state.businessMarkersVisible,
    filterRating: state.filterRating,
    selectedCountryDataFetchStatus: state.cityDataFetchStatus,
    recommendedAreasOn: state.recommendedAreasOn,
    minimumNumberOfRatings: state.minimumNumberOfRatings,
    keywords: state.keywords,
  }))

  const permKeywords = useMemo(() => keywords.filter((key) => key.perm), [keywords]).sort((a, b) => a.keyword.localeCompare(b.keyword))



  return (
    <Wrapper>
   <div>
        {permKeywords.map((keyword, index) => (
          <KeywordItem key={index} keyword={keyword} onToggle={toggleKeyword} />
        ))}
      </div>
  
      <Divider  dashed/>
      <div style={{ paddingBottom: 4 }}>
        <Typography.Text>Minimum rating</Typography.Text>
      </div>
      <Row>
        <Col span={16}>
          <Slider
            step={0.1}
            included
            min={1}
            value={filterRating}
            max={5}
            defaultValue={4.5}
            onChange={setFilterRating}
            tooltip={{ formatter: null }}
          />
        </Col>
        <Col span={1}>
          <InputNumber
            min={1}
            max={5}
            step={0.1}
            value={filterRating}
            defaultValue={4.5}
            style={{ marginLeft: 16, width: 80 }}
            onChange={setFilterRating}
          />
        </Col>
      </Row>
      <div style={{ paddingBottom: 4 }}>
        <Typography.Text>Minimum reviews</Typography.Text>
      </div>
      <Row>
        <Col span={16}>
          <Slider
            step={10}
            included
            min={0}
            value={minimumNumberOfRatings}
            max={100}
            defaultValue={5}
            onChange={setMinimumReviews}
            tooltip={{ formatter: null }}
          />
        </Col>
        <Col span={1}>
          <InputNumber
            min={0}
            max={100}
            step={1}
            value={minimumNumberOfRatings}
            defaultValue={10}
            style={{ marginLeft: 16, width: 80 }}
            onChange={setMinimumReviews}
          />
        </Col>
      </Row>

<Divider  dashed/>
      {/* <Container >
        <Typography.Text>Show recommended areas</Typography.Text>

        <Switch checked={recommendedAreasOn} size="small" onChange={setRecommendedAreasOn} />
      </Container> */}
      <Container>
    
        <Typography.Text>Show location markers</Typography.Text>
        <Switch checked={businessMarkersVisible} size="small" onChange={setBusinessMarkersVisible} />
      </Container>


    </Wrapper>
  )
}
