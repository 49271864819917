import { styled } from '@stitches/react'
import { Alert } from 'antd'
import { BsOption } from 'react-icons/bs'


const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 18,
  height: 18,
  marginRight: 8,
  borderRadius: 4,
  border: '1px solid rgba(128, 128, 128,1)',
  backgroundColor: 'white',
})

const IconsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
})

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  width: '100%',

})

const SearchAreaMacIcons = () => (
  <IconsContainer>
    Hold
    <IconWrapper style={{ marginLeft: 8 }}>
      <BsOption />
    </IconWrapper>
    {/* <IconWrapper>
      <TbHandClick />
    </IconWrapper> */}
    over map + click to search
  </IconsContainer>
)


const SearchAreaIconsRoot = () => (
  <Alert type="info" message={
  <IconsContainer>
    Hold
    <IconWrapper style={{ marginLeft: 8, fontSize: 12, alignItems: 'center', fontWeight: 'bold'  }}>
      ALT
    </IconWrapper>
    {/* <IconWrapper>
      <TbHandClick />
    </IconWrapper> */}
    over map + click to search
  </IconsContainer>
  } />
)

function getOperatingSystem() {
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

  if (macosPlatforms.includes(platform)) {
    return 'Mac'
  } else if (windowsPlatforms.includes(platform)) {
    return 'Win'
  } else if (!platform.indexOf('Linux')) {
    return 'Lin'
  }

  return 'Unknown'
}

export const SearchAreaIcons = () => {
  const os = getOperatingSystem()

  // Sort homes by score in descending order

  return (
    <Container>
      {os === 'Mac' ? (
        <>
               
          <SearchAreaMacIcons />
  
        </>
      ) : (
        <>
              
          <SearchAreaIconsRoot />

        </>
      )}
    </Container>
  )
}
