import L from 'leaflet';
import { Marker, Popup } from "react-leaflet";
import { Home } from "shared/types";
import { deleteHome, onHomeClick } from "store/global";

interface HomeMapMarkerIconProps {
    home: Home;
    selectedHome?: Home
}

export const HomeMapMarkerIcon = ({home, selectedHome}:HomeMapMarkerIconProps ) => {

    const { lat, lng } = home.geometry;

    const innerCircleBorderColor = home.color === '#fff' || home === selectedHome ? '#000' : '#fff';
    const borderWidth = home === selectedHome ? '2px' : '1px';
    const innerCircleWidth = home === selectedHome ? '12px' : '10px';
  
// CSS for the marker
    const markerHtmlStyles = `
      background-color: ${home.color};
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      left: -1.5rem;
      top: -1.5rem;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: ${borderWidth} solid ${innerCircleBorderColor};
    `;
  
    // CSS for the inner circle
    const innerCircleStyles = `
      background-color: #fff;
      width: ${innerCircleWidth};
      height: ${innerCircleWidth};
      border-radius: 50%;
      border: ${borderWidth} solid ${innerCircleBorderColor};
    `;
  
    // CSS for the ranking number
    const rankingNumberStyles = `
      position: absolute;
      bottom: 5px;
      right: -22px; /* Moved further to the right */
      background-color: #FFD700; /* Yellow background */
      border: 1px solid ${selectedHome?.id === home.id ? '#000' : "#fff"}; /* Black border */
      border-radius: 50%;
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.7rem;
      color: #000; /* Black text color */
      transform: rotate(-45deg); /* Counter-rotate to straighten the number */
    `;

  
    const icon = L.divIcon({
      className: 'my-custom-pin',
      iconAnchor: [0, 24],
      popupAnchor: [-7, -48],
      html: `<span style="${markerHtmlStyles}">
               <span style="${innerCircleStyles}"></span>
               ${home.rank ? `<span style="${rankingNumberStyles}">${home.rank}</span>` : ''}
             </span>`,
    });
  
    return (
      <Marker
        icon={icon}
        key={home.id}
        zIndexOffset={1000}
        position={[lat, lng]}
        eventHandlers={{
          click: () => {
            onHomeClick(home);
          },
          contextmenu:  ()=> {
              deleteHome(home)
          },
          mouseover: (e) => {
            e.target.openPopup();
          },
          mouseout: (e) => {
            e.target.closePopup();
          },
        }}
      >
        <Popup closeButton={false}>
          <div>{home.formatted}</div>
        </Popup>
      </Marker>
    );
  };