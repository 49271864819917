import { useEffect } from 'react'
import { useGlobalStore } from 'store/global/store'

export const useCmdShiftPressed = () => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey) {
        useGlobalStore.setState({ cursorSearchMode: true })
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.altKey) {
        useGlobalStore.setState({ cursorSearchMode: false })
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])
}
