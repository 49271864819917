import { Typography } from 'antd'

interface IconTextProps {
  text?: string
  icon: React.ReactNode
}

export const IconText: React.FC<IconTextProps> = ({ text, icon }) => {
  if (!text) return null
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: 16 }}>{icon}</div>
      <Typography.Text>{text}</Typography.Text>
    </div>
  )
}
