import { Typography } from 'antd'
import React from 'react'
import { styled } from 'stitches.config'

const HorizontalTabRoot = styled('div', {
  opacity: 0.8,
  width: '120px',
  zIndex: 2,
  height: '50px',
  position: 'absolute',
  cursor: 'pointer',
  display: 'flex',
  letterSpacing: '1px',
  alignItems: 'center',
  border: '1px solid hsla(0, 0%, 75%, 0.8)',
  justifyContent: 'center',
  //   position: 'relative',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
  boxShadow: '0rem 0.875rem 1.5rem 0rem $colors$shadow',
  transition: 'all 0.3s',
  left: -25,

  '&:hover': {
    opacity: 0.9,
  },

  variants: {
    open: {
      true: {
        left: -10,
        opacity: 0.9,
      },
    },
  },
})

interface HorizontalTabProps {
  name: string
  color: string
  setView: () => void
  top: number
  open: boolean
}

export const HorizontalTab: React.FC<HorizontalTabProps> = ({ name, color, top, setView, open }) => {
  const onClickHandler = () => {
    setView()
  }

  return (
    <HorizontalTabRoot onClick={onClickHandler} style={{ backgroundColor: color, top: top }} open={open}>
      <Typography.Text>{name}</Typography.Text>
    </HorizontalTabRoot>
  )
}
