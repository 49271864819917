import { Collapse, CollapseProps, Layout } from 'antd'
import { MapSettings } from 'features/LeftSidebar/MapSettings/MapSettings'
import { styled } from 'stitches.config'
import { SearchHomes } from './SearchHomes'
import { SearchKeywords } from './SearchKeywords'

// const TabContainer = styled('div', {
//   display: 'flex',
//   position: 'relative',
// })

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
})

export const LeftSidebar = () => {
  const items: CollapseProps['items'] = [
    {
      key: 'area',
      label: <span style={{ fontWeight: '600', color: '#28111A' }}>Recommended area settings</span>,
      children: <MapSettings />,
    },
    {
      key: 'homes',
      label: <span style={{ fontWeight: '600', color: '#28111A' }}>Addresses</span>,
      children: <SearchHomes />,
    },
    {
      key: 'search',
      label: <span style={{ fontWeight: '600' }}>Search for places</span>,
      children: <SearchKeywords />,
    },
  ]

  return (
    <Container>
      <Layout.Sider
        width={320}
        theme="light"
        style={{
          background: '#FAFAFA',
          maxHeight: '100vh',
          position: 'relative',
          overflowY: 'auto',

          zIndex: 3,
        }}
      >
        <div
          style={{
            backgroundColor: '#FAFAFA',
            margin: 'auto',
            paddingTop: 8,
            zIndex: 10,
            top: 0,
            position: 'sticky',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="/trotkit_logo2.png"
            alt="Stay in Best Area Logo"
            style={{
              maxWidth: 160,
            }}
          />
        </div>
        {/* <GlobalSearch /> */}
        <Collapse
          style={{ background: 'transparent' }}
          bordered={false}
          collapsible="header"
          defaultActiveKey={['area', 'homes']}
          expandIconPosition="end"
          items={items}
        />
      </Layout.Sider>
      {/* <TabContainer>
        <HorizontalTab
          name="Map"
          color="hsla(168, 100%, 36%)"
          top={90}
          open={view === 'map'}
          setView={() => {
            setView('map')
          }}
        />
        <HorizontalTab  
          name="Analysis"
          color="hsla(11, 90%, 80%)"
          top={45}
          open={view === 'analysis'}
          setView={() => {
            setView('analysis')

            setDrawerWidth(0)
          }}
        />
      </TabContainer> */}
    </Container>
  )
}
