import { createStitches } from '@stitches/react'

export const { styled, css, globalCss } = createStitches({
  prefix: '',
  utils: {},
})


export const globalStyles = globalCss({
  '.no-pointer-events-icon': {
    pointerEvents: 'none !important' ,
  },
  '.area-polygon': {
    fill: '#106beb !important',
    strokeWidth: '2px',
    '&:hover': {
      fill: '#6B2417 !important',
      stroke: '#6B2417 !important',
    },
  },
});

