import { CloseCircleFilled, CheckCircleTwoTone } from '@ant-design/icons'
import { styled } from '@stitches/react'
import { Spin, Typography } from 'antd'
import { FetchStatus } from 'shared/types'
import { useGlobalStore } from 'store/global'

interface SearchHelpProps {
  status?: FetchStatus
  resultNumber?: number
  duplicateNumber?: number
}

export const HelpContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: 40,
  width: '100%',
})

export const SearchHelp = ({ status, resultNumber = 0, duplicateNumber = 0}: SearchHelpProps) => {
  const { userScans } = useGlobalStore((state) => ({
    userScans: state.userScans,
  }))


  if(!status) return     

  if (status === FetchStatus.FETCHING) {
    return (
      <HelpContainer>
        <Spin
          size="small"
          style={{
            marginRight: 8,
          }}
        ></Spin>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          Searching for keywords nearby...
        </Typography.Text>{' '}
      </HelpContainer>
    )
  }

  if (status === FetchStatus.ERROR) {
    return (
      <HelpContainer>
        <Typography.Text type="danger" style={{ fontSize: 12 }}>
          <CloseCircleFilled style={{ marginRight: 8, color: 'red' }} /> Error during search. Tell Edmond.
        </Typography.Text>
      </HelpContainer>
    )
  }

  if (status === FetchStatus.SUCCESS) {
    return (
      <HelpContainer>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{
              marginRight: 8,
            }}
          />
          {duplicateNumber} results found. {userScans} searches left.
        </Typography.Text>
      </HelpContainer>
    )
  }

  return <HelpContainer />
}
