import { styled } from 'stitches.config'

const InnerPath = styled('path', {
  fill: 'white',
  transform: 'scale(0.8)',
  transformOrigin: 'center',
})

const Svg = styled('svg', {
  marginRight: 16,
  width: 30,
  height: 30,
  variants: {
    size: {
      small: {
        width: 16,
        height: 16,
      },
      large: {
        width: '35px',
        height: '50px',
      },

      medium: {
        width: '25px',
        height: '35px',
      },
    },
  },
})

const Ranking = styled('div', {
  position: 'absolute',
  bottom: '5px',
  right: 3,
  backgroundColor: '#FFD700',
  border: '1px solid',
  borderColor: '#000',
  borderRadius: '50%',
  width: 15,
  height: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.7rem',
  color: '#000',

  variants: {
    adjacent: {
      true: {
        width: 12,
        height: 12,
        bottom: 1,
        right: -2,
        fontSize: 10,
      },
      false: {},
    },
  },
})

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: 'fit-content',
})

interface HomeMarkerIconProps {
  color: string
  size?: 'small' | 'large' | 'medium'
  rank?: number
  adjacent?: boolean
}

export const HomeMarkerIcon = ({ color, size = 'small', rank, adjacent }: HomeMarkerIconProps) => (
  <Container>
    <Svg viewBox="64 64 896 896" focusable="false" data-icon="environment" aria-hidden="true" size={size}>
      <path
        fill={color}
        stroke="black"
        strokeWidth="50"
        d="M512 327c-29.9 0-58 11.6-79.2 32.8A111.6 111.6 0 00400 439c0 29.9 11.7 58 32.8 79.2A111.6 111.6 0 00512 551c29.9 0 58-11.7 79.2-32.8C612.4 497 624 468.9 624 439c0-29.9-11.6-58-32.8-79.2S541.9 327 512 327zm342.6-37.9a362.49 362.49 0 00-79.9-115.7 370.83 370.83 0 00-118.2-77.8C610.7 76.6 562.1 67 512 67c-50.1 0-98.7 9.6-144.5 28.5-44.3 18.3-84 44.5-118.2 77.8A363.6 363.6 0 00169.4 289c-19.5 45-29.4 92.8-29.4 142 0 70.6 16.9 140.9 50.1 208.7 26.7 54.5 64 107.6 111 158.1 80.3 86.2 164.5 138.9 188.4 153a43.9 43.9 0 0022.4 6.1c7.8 0 15.5-2 22.4-6.1 23.9-14.1 108.1-66.8 188.4-153 47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142z"
      ></path>
      <InnerPath
        stroke="black"
        strokeWidth="60"
        d="M512 615c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"
      ></InnerPath>
    </Svg>
    {rank && <Ranking adjacent={adjacent}>{rank}</Ranking>}
  </Container>
)
