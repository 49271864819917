import { SignInButton, useAuth } from '@clerk/clerk-react'
import { Button } from 'antd'
import { Header as HeaderRoot } from 'antd/es/layout/layout'
import { api } from 'api'
import { styled } from 'stitches.config'

const Header = styled(HeaderRoot, {
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingLeft: 32,
  paddingRight: 32,
})

const BuyButton = styled(Button, {
  background: '#4CAF50',
  color: '#fff',
  width: '100px',
  marginRight: '16px',
  fontWeight: 600,
  '&:hover': {
    background: '#388E3C',
    color: '#fff !important',
  },
})
const Logo = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto',
  fontSize: '32px',
  letterSpacing: 2,
  fontFamily: 'Futura, sans-serif',

  color: '#505050',

  '& > span': {
    fontSize: '40px',
  },
})

const handleBuyClick = async () => {
  const response = await api.postStripe()

  if (response.status === 200) {
    window.location.href = response.data
  }
}

export const Navbar: React.FC = () => {
  const auth = useAuth()

  return (
    <Header style={{ display: 'flex', alignItems: 'center' }}>
      <Logo>
        <span role="img" aria-label="map" style={{ marginRight: 8 }}>
          🗺️
        </span>
        MAPCHECK
      </Logo>

      {!auth.isSignedIn && (
        <BuyButton size="large" onClick={handleBuyClick}>
          Buy
        </BuyButton>
      )}

      <SignInButton mode="modal">
        <Button size="large">Sign-in</Button>
      </SignInButton>

      {auth.isSignedIn && <div>Welcome, user 🫶!</div>}
    </Header>
  )
}
