import { styled } from '@stitches/react'
import { Tooltip, Typography } from 'antd'
import { DeleteIcon, DeleteIconButton } from 'components'
import { HomeMarkerIcon } from 'components/HomeMarkerIcon'
import { useMemo } from 'react'
import { Home } from 'shared/types'
import { deleteHome, onHomeClick, useGlobalStore } from 'store/global'

export const LocationContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2px 8px',
  cursor: 'pointer',


  '&:hover': {
    backgroundColor: '#EFEFEF',
  },
})

const LocationText = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 180,
  fontSize: 14,
})



const Overflow = styled('div', {
  overflow: 'auto',
  maxHeight: 120,
  paddingRight: 4
})

export const Homes = () => {
  const { comparisonLocations } = useGlobalStore((state) => ({ comparisonLocations: state.savedHomes }))
  const comparisonLocationsArray = useMemo(() => Array.from(comparisonLocations), [comparisonLocations])

  const onClick = (home: Home) => {
    onHomeClick(home, true)
  }

  const onDeleteClick = (home: Home) => {
    deleteHome(home)
  }

  if (comparisonLocationsArray.length === 0) {
    return <Typography.Text style={{ opacity: 0.5 }}>No addresses added. Click map and save.</Typography.Text>
  }

  return (
    <Overflow>
      {comparisonLocationsArray.map((home, index) => (
        <Tooltip title={home.formatted} key={index}>
          <LocationContainer key={index} onClick={() => onClick(home)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 8 }} >
              <HomeMarkerIcon adjacent color={home.color}  rank={home.rank}/>
              </ div >
              <LocationText>{home.formatted}</LocationText>
            </div>

            <DeleteIconButton
    icon={<DeleteIcon/>}
    onClick={(event) => {
      event.stopPropagation()
      onDeleteClick(home)
    }}
  />

          
          </LocationContainer>
        </Tooltip>
      ))}
    </Overflow>
  )
}
