import { Geocode, Business as BusinessRoot } from 'api/gen'

export type SearchLocation = Geocode

export interface Home extends Omit<Geocode, 'id'> {
  color: string
  rank?: number
  id: string
  score?: number

}

export interface Business extends BusinessRoot {
  distance?: number
  userSearched?: boolean
}

// FRONT_END ONLY

export type Keyword = {
  keyword: string
  active: boolean
  color: string
  perm?: boolean
  desc?: string
  alt?: string
}

export enum FetchStatus {
  FETCHING = 'fetching',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type Country = {
  id: number
  name: string
  zoom?: number
  iso3: string
  iso2: string
  numeric_code: string
  phone_code: string
  capital: string
  currency: string
  currency_name: string
  currency_symbol: string
  tld: string
  native: string | null
  region: string
  region_id: string | null
  subregion: string
  subregion_id: string | null
  nationality: string
  timezones:
    | {
        zoneName: string
        gmtOffset: number
        gmtOffsetName: string
        abbreviation: string
        tzName: string
      }[]
    | null
  translations: {
    [key: string]: string
  }
  latitude: string
  longitude: string
  emoji: string
  emojiU: string
}



export type Cluster = {points: L.LatLngTuple[], businesses: Business[], centroid: number[], id: string, rating: number}
