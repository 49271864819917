import { polygon, area as turfArea } from '@turf/turf';
import hull from 'convex-hull';
import clustering from 'density-clustering';
import { useGlobalStore } from 'store/global/store';
import { isPointInPolygon, rankHomes } from 'utility';
import { shallow } from 'zustand/shallow';
import { centroid as turfCentroid } from '@turf/turf';
import { uniqueId } from 'lodash';


export const registerGlobalStoreEffects = () => {
  const subscriptions = [
    useGlobalStore.subscribe((s) => [s.filterRating, s.minimumNumberOfRatings, s.keywords], setFilteredBusinesses, { equalityFn: shallow }),
    useGlobalStore.subscribe((s) => [s.filteredAreaBusinesses], createSuggestedArea, { equalityFn: shallow }),
    useGlobalStore.subscribe((s) => [s.selectedHome], setSelectedHomeOnSwitch, { equalityFn: shallow }),
  ]

  return function unregister() {
    subscriptions.forEach((unsubscribe) => unsubscribe())
  }
}


const setSelectedHomeOnSwitch = () => {
  const { selectedHome, unsavedHome } = useGlobalStore.getState();


  if(selectedHome?.id !== unsavedHome?.id) {
    useGlobalStore.setState({
      unsavedHome: undefined,
    })
  }
}

const createSuggestedArea = () => {
  const { filteredAreaBusinesses: filteredBusinesses } = useGlobalStore.getState();
  const dataPoints = filteredBusinesses.map((b) => [b.location.latitude, b.location.longitude]); 

  var dbscan = new clustering.DBSCAN();
  var clusters = dbscan.run(dataPoints, 0.005, 4);

  const clusterHullsMeta = clusters.map((cluster) => {
    const pointsAndBusinesses = cluster.map(index => {
      return { point: dataPoints[index]  };
    });

    // Find the convex hull
    const hullIndices = hull(pointsAndBusinesses.map(p => p.point));
    const hullPoints = hullIndices.map((ind: number[]) => pointsAndBusinesses[ind[0]].point);
    const copiedHullPoints = [...hullPoints];

    if (copiedHullPoints.length > 0 && copiedHullPoints[0] !== copiedHullPoints[copiedHullPoints.length - 1]) {
      copiedHullPoints.push(copiedHullPoints[0]);
    }
    
    const invertLatLongForTurf = copiedHullPoints.map((p: [number, number]) => [p[1], p[0]]).filter(el => el.length > 0)
  
    if (invertLatLongForTurf.length < 4) {
      // Handle error - the polygon cannot be formed
      console.error("Not enough points to form a polygon");
      return null
    }

    
    const hullPolygon = polygon([invertLatLongForTurf]);
    const area = turfArea(hullPolygon);
    const hullCentroid = turfCentroid(polygon([invertLatLongForTurf]));

    return {
      points: hullPoints,
      businesses: filteredBusinesses.filter((b) => isPointInPolygon([b.location.latitude, b.location.longitude], hullPoints)),
      area: area,
      size: cluster.length,
      centroid: [hullCentroid.geometry.coordinates[1], hullCentroid.geometry.coordinates[0]]
    };
  })
  .filter(hull =>  !!hull).filter(hull => hull!.area >= 200000);


  const sortedClusterHullsMeta = clusterHullsMeta.sort((a, b) => b!.size - a!.size);

  const clusterHulls = clusterHullsMeta.map((hull, index) => ({
    points: hull!.points,
    businesses: hull!.businesses,// Include businesses in the state
    centroid: hull!.centroid,
    id: uniqueId('cluster_'),
    rating: index + 1
  }));

  const clusterHullCentroids = sortedClusterHullsMeta.slice(0, 5).map((hull) => hull!.centroid);

  useGlobalStore.setState({
    clusters: clusterHulls,
    clusterHullCentroids,
  });
};



 const setFilteredBusinesses = () => {
  const { businesses, savedHomes, filterRating, userSearchBusinesses, keywords, minimumNumberOfRatings } = useGlobalStore.getState()
 
  const activeKeywords = keywords.filter((k) => k.active).map((k) => k.keyword);


  const filteredBusinesses = businesses.filter(
    (b) => filterRating <= b.rating  && minimumNumberOfRatings <= b.userRatingCount && b.types.some((t) => activeKeywords.includes(t)),
  )

  const rankedHomes = rankHomes(savedHomes, keywords, [...filteredBusinesses, ...userSearchBusinesses])

  useGlobalStore.setState({
    filteredAreaBusinesses: filteredBusinesses, 
    savedHomes: rankedHomes,
  })
}
