import { Alert, Button, Divider, Form, Input, Typography } from 'antd'
import { HelpContainer, SearchHelp } from 'components'
import { KeywordItem } from 'components/KeywordItem'
import { SearchAreaIcons } from 'features/LeftSidebar/SearchKeywords/SearchAreaIcons'

import React, { useMemo, useState } from 'react'
import { FetchStatus } from 'shared/types'
import { addKeyword, deleteKeyword, useGlobalStore } from 'store/global'

const { Search } = Input

const validateKeywords = (value: string) => {
  const keywords = value.split(/[\s,]+/).map((keyword) => keyword.trim())

  const MAX_LENGTH = 20
  const isLengthValid = keywords.every((keyword) => keyword.length > 0 && keyword.length <= MAX_LENGTH)
  const isCharacterValid = keywords.every((keyword) => /^[a-zA-Z0-9]+$/.test(keyword))
  const isDuplicateValid = new Set(keywords).size === keywords.length

  return isLengthValid && isCharacterValid && isDuplicateValid
}

const validateForDuplicateKeywords = (value: string, oldKeywords: string[]) => {
  const newKeywords = value.split(/[\s,]+/).map((keyword) => keyword.trim())

  const lowerCaseOldKeywords = oldKeywords.map((keyword) => keyword.toLowerCase())
  const lowerCaseNewKeywords = newKeywords.map((keyword) => keyword.toLowerCase())

  if (lowerCaseNewKeywords.some((keyword) => lowerCaseOldKeywords.includes(keyword))) {
    return false
  }

  return true
}

export const SearchKeywords: React.FC = () => {
  const store = useGlobalStore((state) => ({
    keywordFetchStatus: state.keywordFetchStatus,
    newKeywordBusinessCount: state.newKeywordBusinessCount,
    filterRating: state.filterRating,
    newKeywordNonDuplicateCount: state.newKeywordNonDuplicateCount
  }))

  const [input, setInput] = useState('')
  const keywords = useGlobalStore((state) => state.keywords)
  const [error, setError] = useState('')

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setInput(event.target.value)
  }

  const onSearch = (value: string) => {
    if (value) {
      const incorrectValues = !validateKeywords(value)
      if (incorrectValues) {
        setError('Enter comma separated keywords')
        return
      }

      const duplicateStrings = !validateForDuplicateKeywords(
        value,
        keywords.map((keyword) => keyword.keyword),
      )

      if (duplicateStrings) {
        setError('Duplicate keywords')
        return
      }

      const newKeywords = value.split(',').map((keyword) => keyword.trim())
      addKeyword(newKeywords)
      setError('')
    }
  }


  const userKeywords = useMemo(() => keywords.filter((key) => !key.perm), [keywords])

  return (
    <>
      <Form>
        <Form.Item
          validateStatus={error ? 'error' : undefined}
          help={
            error ? (
              <HelpContainer>
                <Typography.Text type="danger">{error}</Typography.Text>
              </HelpContainer>
            ) : (
              <SearchHelp resultNumber={store.newKeywordBusinessCount} duplicateNumber={store.newKeywordNonDuplicateCount} status={store.keywordFetchStatus} />
            )
          }
        >
          <Search
            value={input}
            onChange={onInputChange}
            onSearch={onSearch}
            disabled={store.keywordFetchStatus === FetchStatus.FETCHING}
            placeholder="muay thai, vegan"
            size="middle"
            style={{
              backgroundColor: 'white',
            }}
            enterButton={
              <Button
                disabled={store.keywordFetchStatus === FetchStatus.FETCHING}
                type="primary"
                style={{
                  backgroundColor: store.keywordFetchStatus === FetchStatus.FETCHING ? 'lightgray' : '#1677ff',
                  width: 77,
                }}
              >
                Add
              </Button>
            }
          ></Search>
        </Form.Item>
      </Form>
      <Divider dashed />
      <div style={{ marginBottom: 8 }}>
        <Typography.Text>Things to search for</Typography.Text>
      </div>
              
      {!!keywords.filter(k => !k.perm).length && <Alert style={{marginBottom: 8}} showIcon   type="info" message={<SearchAreaIcons />} />}
      {userKeywords.length === 0 && (
        <Typography.Text style={{ opacity: 0.5 }}>You haven't added anything yet</Typography.Text>
      )}

      {userKeywords.map((keyword, index) => (
        <KeywordItem key={index} keyword={keyword} onDelete={deleteKeyword} />
      ))}





    </>
  )
}
