import { GoogleOutlined, StarFilled } from '@ant-design/icons'
import { Button, Divider, Typography } from 'antd'
import { IconLink } from 'components'
import { HomeMarkerIcon } from 'components/HomeMarkerIcon'
import { calculatePriceLevel } from 'features/RightSidebar/BusinessDetails'
import { Fragment } from 'react'
import { styled } from 'stitches.config'
import {
  deleteHome,
  onBusinessClick,
  onSaveHomeClick,
  setHoveredBusiness,
  useGlobalStore
} from 'store/global'
import { calculateClosestBusinesses, calculateHighestRatedBusinessesWithingWalkingDistance, getOrdinalSuffix } from 'utility'

export const SidebarItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2px 8px',

  '&:hover': {
    backgroundColor: '#EFEFEF',
    cursor: 'pointer',
  },
})

const LocationText = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 340,
  fontSize: 18,
})

const DividerIfLastOfTypeDontShow = styled(Divider, {
  '&:last-of-type': {
    display: 'none',
  },
})



function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const HomeDetails = () => {
  const { selectedHome, drawerWidth, keywords, filteredBusinesses, unsavedHome, userSearchBusinesses} = useGlobalStore(
    (state) => ({
      selectedHome: state.selectedHome,
      drawerWidth: state.drawerWidth,
      keywords: state.keywords,
      filteredBusinesses: state.filteredAreaBusinesses,
      unsavedHome: state.unsavedHome,
      userSearchBusinesses: state.userSearchBusinesses
    }),
  )

  if (!selectedHome) return null

  const closestBusinesses = calculateClosestBusinesses(selectedHome, keywords, [...filteredBusinesses, ...userSearchBusinesses])
  const highestRatedBusinesses =  calculateHighestRatedBusinessesWithingWalkingDistance(selectedHome, keywords, [...filteredBusinesses, ...userSearchBusinesses])

  const inUnsavedHome = unsavedHome?.id === selectedHome.id

  const text = selectedHome && selectedHome.rank ? `Address ranked ${getOrdinalSuffix(selectedHome.rank)} due to its nearness to each type of place.` : null


  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center'}}>
  
<div style={{ padding: '0px 24px',display: 'flex', marginTop: 16,  alignItems: 'center'}}>
        <div>
          <HomeMarkerIcon size={'large'} color={selectedHome.color} rank={selectedHome.rank} />
        </div>
        <LocationText style={{fontWeight: '600', fontSize: 24, marginLeft: 4  }} >{selectedHome.formatted}</LocationText>
        </div>
      </div>
      
      <div
        style={{
          padding: 24,
          paddingTop: 8,
          paddingBottom: 8,
          width: `calc(${drawerWidth}px - 50px)`,
          minWidth: `calc(${drawerWidth}px - 50px)`,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 124px)',
          // border: '1px solid',
          overflow: 'hidden',
        }}
      >
        <IconLink
          text="maps.google.com"
          icon={<GoogleOutlined style={{ color: '#1890ff', fontSize: 18, margin: 8, marginRight: 4 }} />}
          url={`https://www.google.com/maps/?q=${selectedHome.geometry.lat},${selectedHome.geometry.lng}`}
        />
     {text && <div style={{ marginTop: 16 }}>
        <Typography.Text>{text}</Typography.Text>
      </div>}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginTop: 24}}>
       
            <Typography.Title level={5} style={{ fontWeight: '600', color: '#28111A', marginTop: 0}}>
              {' '}
              Best places within walking distance
            </Typography.Title>
     
        </div>
        <div style={{ overflowY: 'auto', marginBottom: '16px' }}>
       {highestRatedBusinesses.every(keyword => keyword.businesses.length === 0) && <Typography.Text style={{ fontWeight: '400', color: '#28111A' }}>
            None found nearby
         </Typography.Text>}

        {highestRatedBusinesses?.map((closest, index) => {
             const keyword = keywords.find((k) => k.keyword === closest.keyword)

            if (closest.businesses.length === 0 || !keyword?.active ) return null



            const header = keyword?.desc  ? capitalize(keyword.desc) : capitalize(closest.keyword)

            

            return (
              <Fragment key={index}>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 8,
                    }}
                  >
                    <div>
                      <Typography.Text style={{ fontWeight: '600', color: '#28111A' }}>
                       {header}
                      </Typography.Text>
                    </div>
                  </div>

                  {closest.businesses.slice(0, 5).map((bis, bisIndex) => {
                    return (
                      <SidebarItemContainer
                     
                        onClick={() => onBusinessClick(bis)}
                        key={bisIndex}
                        onMouseEnter={() => setHoveredBusiness(bis)}
                        onMouseLeave={() => setHoveredBusiness(undefined)}
                      >
                        <LocationText
                          style={{
                            fontSize: '14px',
                            maxWidth: 180,
                          }}
                        >
                          {bis.displayName.text}
                        </LocationText>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography.Text type="secondary"> {bis.rating || ' '} </Typography.Text>
                          <StarFilled style={{ color: '#FBBC03', marginLeft: 4, marginRight: 4 }} />
                          <Typography.Text type="secondary">
                            {bis.userRatingCount ? `(${bis.userRatingCount}) ` : ' '}
                            {/* {bis.price ? `• ${bis.price} ` : ' '} */}
                            {bis.distance ? `• ${Math.round(bis.distance * 10) / 10} km` : ' '}
                          </Typography.Text>
                        </div>
                      </SidebarItemContainer>
                    )
                  })}
                </div>
                <DividerIfLastOfTypeDontShow dashed />
              </Fragment>
            )
          })}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginTop: 24}}>
   
            <Typography.Title level={5} style={{ fontWeight: '600', color: '#28111A', marginTop: 0}}>
              {' '}
              Nearest places
            </Typography.Title>
         
        </div>
        {highestRatedBusinesses.every(keyword => keyword.businesses.length === 0) && <Typography.Text style={{ fontWeight: '400', color: '#28111A' }}>
            None found nearby
         </Typography.Text>}
        <div style={{ overflowY: 'auto', marginBottom: '16px' }}>
          {closestBusinesses?.map((closest, index) => {
                        const keyword = keywords.find((k) => k.keyword === closest.keyword)
               if (closest.businesses.length === 0 || !keyword?.active ) return null


            const header = keyword?.desc  ? capitalize(keyword.desc) : capitalize(closest.keyword)



            return (
              <Fragment key={index}>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 8,
                    }}
                  >
                    <div>
                      <Typography.Text style={{ fontWeight: '600', color: '#28111A' }}>
                        {header}
                      </Typography.Text>
                    </div>
                  </div>

                  {closest.businesses.slice(0, 5).map((bis, bisIndex) => {
                    return (
                      <SidebarItemContainer
                        onClick={() => onBusinessClick(bis)}
                        key={bisIndex}
                        onMouseEnter={() => setHoveredBusiness(bis)}
                        onMouseLeave={() => setHoveredBusiness(undefined)}
                      >
                        <LocationText
                          style={{
                            fontSize: '14px',
                            maxWidth: 180,
                          }}
                        >
                          {bis.displayName.text}
                        </LocationText>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography.Text type="secondary"> {bis.rating || ' '} </Typography.Text>
                          <StarFilled style={{ color: '#FBBC03', marginLeft: 4, marginRight: 4 }} />
                          <Typography.Text type="secondary">
                            {bis.userRatingCount ? `(${bis.userRatingCount}) ` : ' '}
                            {bis.priceLevel ? ` • ${calculatePriceLevel(bis.priceLevel)}` : ''}
                            {bis.distance ? ` • ${Math.round(bis.distance * 10) / 10} km` : ' '}
                          </Typography.Text>
                        </div>
                      </SidebarItemContainer>
                    )
                  })}
                </div>
                <DividerIfLastOfTypeDontShow dashed />
              </Fragment>
            )
          })}
        </div>
        <div style={{ marginTop: 'auto' }}>
          {inUnsavedHome && (
            <Button type="primary" style={{ width: '100%' }} size='large' onClick={() => onSaveHomeClick(selectedHome)}>
              Save address
            </Button>
          )}
          {!inUnsavedHome && (
            <Button type="primary" danger style={{ width: '100%' }} size='large' onClick={() => deleteHome(selectedHome)}>
              Delete address
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
