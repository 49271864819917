import { api } from 'api'
import { useGlobalStore } from 'store/global/store'

export const useFetchCities = () => {


  const fetchCities = async () => {


  
    const { data } = await api.citiesGet()


    useGlobalStore.setState({
     cities: data
    })
  }

  return { fetchCities }
}
