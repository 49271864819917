import { RedirectToSignIn, useUser } from '@clerk/clerk-react'
import { Page } from 'features'
import { useEffect } from 'react'
import {
  registerGlobalStoreEffects,
  useCmdShiftPressed,
  useFetchCities,
  useFetchHomesBusinessData,
  useFetchUserdata,
  useGlobalStore,
} from 'store/global'

function App() {
  const userContext = useUser()

  const { fetchHomesBusinessData } = useFetchHomesBusinessData()
  const { fetchUserData } = useFetchUserdata()
  const { fetchCities } = useFetchCities()

  useCmdShiftPressed()

  useEffect(() => {
    if (!userContext.isLoaded) {
      return
    }

    if (!userContext.isSignedIn) {
      return
    }

    registerGlobalStoreEffects()
    fetchHomesBusinessData()
    fetchUserData(userContext.user.id)
    fetchCities()

    useGlobalStore.setState({
      userId: userContext.user.id,
    })
  }, [
    fetchHomesBusinessData,
    userContext.isLoaded,
    userContext.user,
    userContext.isSignedIn,
    fetchUserData,
    fetchCities,
  ])

  if (!userContext.isSignedIn) {
    return <RedirectToSignIn />
  }

  return <Page />
}

export default App
