/* tslint:disable */
/* eslint-disable */
/**
 * Map Check Backend
 * Map Check Backend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Business
 */
export interface Business {
  /**
   * A textual identifier that uniquely identifies a place.
   * @type {string}
   * @memberof Business
   */
  id: string
  /**
   *
   * @type {BusinessDisplayName}
   * @memberof Business
   */
  displayName: BusinessDisplayName
  /**
   *
   * @type {BusinessLocation}
   * @memberof Business
   */
  location: BusinessLocation
  /**
   * An array of feature types describing the given result.
   * @type {Array<string>}
   * @memberof Business
   */
  types: Array<string>
  /**
   * The place\'s rating, from 1.0 to 5.0, based on aggregated user reviews.
   * @type {number}
   * @memberof Business
   */
  rating: number
  /**
   * The total number of user ratings for the place.
   * @type {number}
   * @memberof Business
   */
  userRatingCount: number
  /**
   * The primary type that best describes the establishment.
   * @type {string}
   * @memberof Business
   */
  primaryType?: string
  /**
   * The international phone number of the place.
   * @type {string}
   * @memberof Business
   */
  internationalPhoneNumber?: string
  /**
   * A shorter version of the formatted address.
   * @type {string}
   * @memberof Business
   */
  shortFormattedAddress?: string
  /**
   * The URI to the Google Maps page for the place.
   * @type {string}
   * @memberof Business
   */
  googleMapsUri: string
  /**
   * The operational status of the place, for example, OPERATIONAL, CLOSED_TEMPORARILY, or CLOSED_PERMANENTLY.
   * @type {string}
   * @memberof Business
   */
  businessStatus?: string
  /**
   * The price level of the place, e.g., PRICE_LEVEL_INEXPENSIVE, PRICE_LEVEL_EXPENSIVE.
   * @type {string}
   * @memberof Business
   */
  priceLevel?: string
  /**
   * The URI to the website of the place.
   * @type {string}
   * @memberof Business
   */
  websiteUri?: string
  /**
   *
   * @type {BusinessPrimaryTypeDisplayName}
   * @memberof Business
   */
  primaryTypeDisplayName?: BusinessPrimaryTypeDisplayName
}
/**
 *
 * @export
 * @interface BusinessDisplayName
 */
export interface BusinessDisplayName {
  /**
   * The name of the place.
   * @type {string}
   * @memberof BusinessDisplayName
   */
  text: string
  /**
   * The language code of the display name.
   * @type {string}
   * @memberof BusinessDisplayName
   */
  languageCode: string
}
/**
 *
 * @export
 * @interface BusinessLocation
 */
export interface BusinessLocation {
  /**
   * The latitude of the place.
   * @type {number}
   * @memberof BusinessLocation
   */
  latitude: number
  /**
   * The longitude of the place.
   * @type {number}
   * @memberof BusinessLocation
   */
  longitude: number
}
/**
 *
 * @export
 * @interface BusinessPrimaryTypeDisplayName
 */
export interface BusinessPrimaryTypeDisplayName {
  /**
   * The display name of the primary type in a specific language.
   * @type {string}
   * @memberof BusinessPrimaryTypeDisplayName
   */
  text: string
  /**
   * The language code of the primary type display name.
   * @type {string}
   * @memberof BusinessPrimaryTypeDisplayName
   */
  languageCode: string
}
/**
 *
 * @export
 * @interface City
 */
export interface City {
  /**
   * The unique identifier for a city.
   * @type {number}
   * @memberof City
   */
  id: number
  /**
   * The name of the city.
   * @type {string}
   * @memberof City
   */
  name: string
  /**
   * The unique identifier for the state the city is in.
   * @type {number}
   * @memberof City
   */
  state_id: number
  /**
   * The code for the state the city is in.
   * @type {string}
   * @memberof City
   */
  state_code: string
  /**
   * The name of the state the city is in.
   * @type {string}
   * @memberof City
   */
  state_name: string
  /**
   * The unique identifier for the country the city is in.
   * @type {number}
   * @memberof City
   */
  country_id: number
  /**
   * The code for the country the city is in.
   * @type {string}
   * @memberof City
   */
  country_code: string
  /**
   * The name of the country the city is in.
   * @type {string}
   * @memberof City
   */
  country_name: string
  /**
   * The latitude of the city.
   * @type {string}
   * @memberof City
   */
  latitude: string
  /**
   * The longitude of the city.
   * @type {string}
   * @memberof City
   */
  longitude: string
  /**
   * The WikiData ID for the city, if available.
   * @type {string}
   * @memberof City
   */
  wikiDataId?: string | null
}
/**
 *
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
  /**
   *
   * @type {number}
   * @memberof Coordinates
   */
  latitude: number
  /**
   *
   * @type {number}
   * @memberof Coordinates
   */
  longitude: number
}
/**
 *
 * @export
 * @interface Geocode
 */
export interface Geocode {
  /**
   *
   * @type {number}
   * @memberof Geocode
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  formatted: string
  /**
   *
   * @type {number}
   * @memberof Geocode
   */
  confidence: number
  /**
   *
   * @type {GeocodeGeometry}
   * @memberof Geocode
   */
  geometry: GeocodeGeometry
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  country?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  country_code?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  postcode?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  state_code?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  road?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  house_number?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  city_district?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  continent?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  county?: string
  /**
   *
   * @type {string}
   * @memberof Geocode
   */
  suburb?: string
}
/**
 *
 * @export
 * @interface GeocodeGeometry
 */
export interface GeocodeGeometry {
  /**
   *
   * @type {number}
   * @memberof GeocodeGeometry
   */
  lat: number
  /**
   *
   * @type {number}
   * @memberof GeocodeGeometry
   */
  lng: number
}
/**
 *
 * @export
 * @interface GetUserScans200Response
 */
export interface GetUserScans200Response {
  /**
   * The number of user scans
   * @type {number}
   * @memberof GetUserScans200Response
   */
  scans?: number
}
/**
 *
 * @export
 * @interface GetUserScansRequest
 */
export interface GetUserScansRequest {
  /**
   *
   * @type {string}
   * @memberof GetUserScansRequest
   */
  userId: string
}
/**
 *
 * @export
 * @interface PostSearchRequest
 */
export interface PostSearchRequest {
  /**
   *
   * @type {string}
   * @memberof PostSearchRequest
   */
  userId: string
  /**
   * Keywords to search for
   * @type {Array<string>}
   * @memberof PostSearchRequest
   */
  keywords: Array<string>
  /**
   * Radius to search around
   * @type {number}
   * @memberof PostSearchRequest
   */
  radius: number
  /**
   * Coordinates to search around
   * @type {Array<Coordinates>}
   * @memberof PostSearchRequest
   */
  coordinates: Array<Coordinates>
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieve businesses by country code
     * @param {string} countryCode The country code for the businesses
     * @param {number} cityId The city id for the businesses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessCountryCodeCityIdGet: async (
      countryCode: string,
      cityId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists('businessCountryCodeCityIdGet', 'countryCode', countryCode)
      // verify required parameter 'cityId' is not null or undefined
      assertParamExists('businessCountryCodeCityIdGet', 'cityId', cityId)
      const localVarPath = `/business/{country_code}/{city_id}`
        .replace(`{${'country_code'}}`, encodeURIComponent(String(countryCode)))
        .replace(`{${'city_id'}}`, encodeURIComponent(String(cityId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve cities by country code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    citiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve geolocation data by search query
     * @param {string} q The search query for the geolocation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forwardGeocodeGet: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'q' is not null or undefined
      assertParamExists('forwardGeocodeGet', 'q', q)
      const localVarPath = `/forward-geocode`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Retrieve user scans
     * @param {GetUserScansRequest} getUserScansRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserScans: async (
      getUserScansRequest: GetUserScansRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getUserScansRequest' is not null or undefined
      assertParamExists('getUserScans', 'getUserScansRequest', getUserScansRequest)
      const localVarPath = `/clerk/user-scans`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getUserScansRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Search on Google Maps
     * @param {PostSearchRequest} postSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSearch: async (
      postSearchRequest: PostSearchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postSearchRequest' is not null or undefined
      assertParamExists('postSearch', 'postSearchRequest', postSearchRequest)
      const localVarPath = `/search`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(postSearchRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a Stripe Checkout Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStripe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/stripe/pay`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Stripe Webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/stripe/webhook`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve geolocation by lat and lgn
     * @param {number} lat The latitude for the geolocation data
     * @param {number} lng The longitude for the geolocation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reverseGeocodeGet: async (lat: number, lng: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('reverseGeocodeGet', 'lat', lat)
      // verify required parameter 'lng' is not null or undefined
      assertParamExists('reverseGeocodeGet', 'lng', lng)
      const localVarPath = `/reverse-geocode`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat
      }

      if (lng !== undefined) {
        localVarQueryParameter['lng'] = lng
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     * Retrieve businesses by country code
     * @param {string} countryCode The country code for the businesses
     * @param {number} cityId The city id for the businesses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessCountryCodeCityIdGet(
      countryCode: string,
      cityId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Business>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessCountryCodeCityIdGet(
        countryCode,
        cityId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Retrieve cities by country code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async citiesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<City>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.citiesGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Retrieve geolocation data by search query
     * @param {string} q The search query for the geolocation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forwardGeocodeGet(
      q: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Geocode>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forwardGeocodeGet(q, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Retrieve user scans
     * @param {GetUserScansRequest} getUserScansRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserScans(
      getUserScansRequest: GetUserScansRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserScans200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserScans(getUserScansRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Search on Google Maps
     * @param {PostSearchRequest} postSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSearch(
      postSearchRequest: PostSearchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Business>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postSearch(postSearchRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Create a Stripe Checkout Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postStripe(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postStripe(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Stripe Webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postWebhook(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postWebhook(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Retrieve geolocation by lat and lgn
     * @param {number} lat The latitude for the geolocation data
     * @param {number} lng The longitude for the geolocation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reverseGeocodeGet(
      lat: number,
      lng: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Geocode>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reverseGeocodeGet(lat, lng, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration)
  return {
    /**
     * Retrieve businesses by country code
     * @param {string} countryCode The country code for the businesses
     * @param {number} cityId The city id for the businesses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessCountryCodeCityIdGet(countryCode: string, cityId: number, options?: any): AxiosPromise<Array<Business>> {
      return localVarFp
        .businessCountryCodeCityIdGet(countryCode, cityId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve cities by country code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    citiesGet(options?: any): AxiosPromise<Array<City>> {
      return localVarFp.citiesGet(options).then((request) => request(axios, basePath))
    },
    /**
     * Retrieve geolocation data by search query
     * @param {string} q The search query for the geolocation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forwardGeocodeGet(q: string, options?: any): AxiosPromise<Array<Geocode>> {
      return localVarFp.forwardGeocodeGet(q, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Retrieve user scans
     * @param {GetUserScansRequest} getUserScansRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserScans(getUserScansRequest: GetUserScansRequest, options?: any): AxiosPromise<GetUserScans200Response> {
      return localVarFp.getUserScans(getUserScansRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Search on Google Maps
     * @param {PostSearchRequest} postSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSearch(postSearchRequest: PostSearchRequest, options?: any): AxiosPromise<Array<Business>> {
      return localVarFp.postSearch(postSearchRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a Stripe Checkout Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStripe(options?: any): AxiosPromise<string> {
      return localVarFp.postStripe(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Stripe Webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postWebhook(options?: any): AxiosPromise<void> {
      return localVarFp.postWebhook(options).then((request) => request(axios, basePath))
    },
    /**
     * Retrieve geolocation by lat and lgn
     * @param {number} lat The latitude for the geolocation data
     * @param {number} lng The longitude for the geolocation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reverseGeocodeGet(lat: number, lng: number, options?: any): AxiosPromise<Array<Geocode>> {
      return localVarFp.reverseGeocodeGet(lat, lng, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
  /**
   * Retrieve businesses by country code
   * @param {string} countryCode The country code for the businesses
   * @param {number} cityId The city id for the businesses
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  businessCountryCodeCityIdGet(
    countryCode: string,
    cityId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Array<Business>>

  /**
   * Retrieve cities by country code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  citiesGet(options?: AxiosRequestConfig): AxiosPromise<Array<City>>

  /**
   * Retrieve geolocation data by search query
   * @param {string} q The search query for the geolocation data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  forwardGeocodeGet(q: string, options?: AxiosRequestConfig): AxiosPromise<Array<Geocode>>

  /**
   *
   * @summary Retrieve user scans
   * @param {GetUserScansRequest} getUserScansRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  getUserScans(
    getUserScansRequest: GetUserScansRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<GetUserScans200Response>

  /**
   *
   * @summary Search on Google Maps
   * @param {PostSearchRequest} postSearchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  postSearch(postSearchRequest: PostSearchRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Business>>

  /**
   *
   * @summary Create a Stripe Checkout Session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  postStripe(options?: AxiosRequestConfig): AxiosPromise<string>

  /**
   *
   * @summary Stripe Webhook
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  postWebhook(options?: AxiosRequestConfig): AxiosPromise<void>

  /**
   * Retrieve geolocation by lat and lgn
   * @param {number} lat The latitude for the geolocation data
   * @param {number} lng The longitude for the geolocation data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  reverseGeocodeGet(lat: number, lng: number, options?: AxiosRequestConfig): AxiosPromise<Array<Geocode>>
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
  /**
   * Retrieve businesses by country code
   * @param {string} countryCode The country code for the businesses
   * @param {number} cityId The city id for the businesses
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public businessCountryCodeCityIdGet(countryCode: string, cityId: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .businessCountryCodeCityIdGet(countryCode, cityId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve cities by country code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public citiesGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .citiesGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve geolocation data by search query
   * @param {string} q The search query for the geolocation data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public forwardGeocodeGet(q: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .forwardGeocodeGet(q, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Retrieve user scans
   * @param {GetUserScansRequest} getUserScansRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getUserScans(getUserScansRequest: GetUserScansRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getUserScans(getUserScansRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Search on Google Maps
   * @param {PostSearchRequest} postSearchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postSearch(postSearchRequest: PostSearchRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .postSearch(postSearchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a Stripe Checkout Session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postStripe(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .postStripe(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Stripe Webhook
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postWebhook(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .postWebhook(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve geolocation by lat and lgn
   * @param {number} lat The latitude for the geolocation data
   * @param {number} lng The longitude for the geolocation data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public reverseGeocodeGet(lat: number, lng: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .reverseGeocodeGet(lat, lng, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
