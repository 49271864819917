import { DefaultApiFactory } from './gen'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

if (!REACT_APP_API_URL) {
  throw new Error('REACT_APP_API_URL is not defined')
}

export const api = DefaultApiFactory({
  isJsonMime: (mime: string) => mime === 'application/json',
  basePath: REACT_APP_API_URL ? REACT_APP_API_URL : 'http://localhost:3001/api/v1',
})
