import { DeleteOutlined } from '@ant-design/icons'
import { styled } from '@stitches/react'
import { Switch, Tooltip } from 'antd'
import { KeywordCircle } from 'components'
import { Keyword } from 'shared/types'
import { setHoveredKeyword } from 'store/global'
import { Button } from 'antd';


export const SidebarItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: '2px 8px',

  '&:hover': {
    backgroundColor: '#EFEFEF',
  },
})

const KeywordText = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '150px',
})

const IconWrapper = styled('div', {
  fontSize: '8px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    fontSize: '12px',
    fill: '#666', // Change color on hover
  },
});


export const DeleteIcon = () => (
  <IconWrapper>
    <DeleteOutlined />
  </IconWrapper>
);

export const DeleteIconButton = styled(Button, {
  border: 'none',
  padding: 0,
  background: 'transparent',
  boxShadow: 'none',

  height: 20,
  cursor: 'pointer',
  fontSize: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  

  '&:hover, &:focus': {
    background: 'transparent',
    border: 'none',
    '& svg': {
      fill: 'black',
    },
 
  },
});

type SidebarItemProps = {
  keyword: Keyword
  onToggle?: (keyword: string) => void
  onDelete?: (keyword: string) => void
}

export const KeywordItem: React.FC<SidebarItemProps> = ({ keyword, onToggle, onDelete, ...props }) => {
  return (
    <SidebarItemContainer
      {...props}
      onMouseEnter={() => setHoveredKeyword(keyword)}
      onMouseLeave={() => setHoveredKeyword(undefined)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <KeywordCircle style={{ backgroundColor: keyword.color }} />
        <KeywordText>{keyword.alt || keyword.keyword}</KeywordText>
      </div>
      <div>
      {onDelete && (
  <DeleteIconButton
    icon={<DeleteIcon/>}
    onClick={(event) => {
      event.stopPropagation();
      onDelete(keyword.keyword);
    }}
  />
)}
       {onToggle && (<Tooltip placement="right" title={keyword.active ? 'Remove places' : 'Add places'}>
          <Switch
            size="small"
            checked={keyword.active}
            onChange={(event) => {
              onToggle(keyword.keyword)
            }}
          />
        </Tooltip>)}
      </div>
    </SidebarItemContainer>
  )
}
