import { Business, Cluster, Home, Keyword } from 'shared/types'
import { useGlobalStore } from 'store/global'

function distance(lat1: number, lng1: number, lat2: number, lng2: number) {
  const R = 6371e3 // Earth's radius in meters
  const lat1Rad = (lat1 * Math.PI) / 180
  const lat2Rad = (lat2 * Math.PI) / 180
  const deltaLat = ((lat2 - lat1) * Math.PI) / 180
  const deltaLng = ((lng2 - lng1) * Math.PI) / 180

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

export function getDataByLocation(lat: number, lng: number, data: any) {
  const filteredData = data.filter((obj: any) => distance(lat, lng, obj.lat, obj.lng) <= 500)

  const result = filteredData.reduce((acc: any, obj: any) => {
    if (acc[obj.keyword]) {
      acc[obj.keyword].count += 1
      acc[obj.keyword].totalRating += obj.rating
      acc[obj.keyword].totalRatings += obj.ratings
    } else {
      acc[obj.keyword] = {
        keyword: obj.keyword,
        count: 1,
        totalRating: obj.rating,
        totalRatings: obj.ratings,
      }
    }

    return acc
  }, {})

  // add keyword present in data but not in filteredData
  data.forEach((obj: any) => {
    if (!result[obj.keyword]) {
      result[obj.keyword] = {
        keyword: obj.keyword,
        count: 0,
        totalRating: 0,
        totalRatings: '-',
      }
    }
  })

  return Object.values(result).map((obj: any, index) => ({
    key: index.toString(),
    keyword: obj.keyword,
    averageRating: obj.totalRating === 0 ? '-' : (obj.totalRating / obj.count).toFixed(2),
    count: obj.count,
    totalRatings: obj.totalRatings,
  }))
}

export function getDistanceFromLatLonInKM(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180)
}

const MAXIMUM_DISTANCE = 5

export const calculateClosestBusinesses = (home: Home, keywords: Keyword[], filteredBusinesses: Business[]) => {
  const nearestBusinessPerKeyword = filteredBusinesses
    .map((business) => ({
      ...business,
      distance: getDistanceFromLatLonInKM(home.geometry.lat, home.geometry.lng, business.location.latitude, business.location.longitude),
    }))
    .filter((business) => business.distance <= MAXIMUM_DISTANCE)

  const closestBusinesses = keywords.map((keyword) => {
    const associatedBusinesses = nearestBusinessPerKeyword.filter((b) => {
      return b.types.includes(keyword.keyword)
    })

    return {
      keyword: keyword.keyword,
      businesses: associatedBusinesses.sort((a, b) => a.distance - b.distance),
    }
  })

  return closestBusinesses
}

const MAXIMUM_WALKING_DISTANCE = 3

export const calculateHighestRatedBusinessesWithingWalkingDistance = (home: Home, keywords: Keyword[], filteredBusinesses: Business[]) => {
  const nearestBusinessPerKeyword = filteredBusinesses
    .map((business) => ({
      ...business,
      distance: getDistanceFromLatLonInKM(home.geometry.lat, home.geometry.lng, business.location.latitude, business.location.longitude),
    }))
    .filter((business) => business.distance <= MAXIMUM_WALKING_DISTANCE)

  const highestRatedBusinessesCloseBy = keywords.map((keyword) => {
    const associatedBusinesses = nearestBusinessPerKeyword.filter((b) => {
      return b.types.includes(keyword.keyword)
    })

    return {
      keyword: keyword.keyword,
      businesses: associatedBusinesses.sort((a, b) => b.rating - a.rating),
    }
  })

  return highestRatedBusinessesCloseBy
}

export const processBusinesses = (businesses: Business[], keywords: Keyword[]) => {
  const {  filterRating, minimumNumberOfRatings} = useGlobalStore.getState()


  const deduplicatedBusinesses = businesses.filter((business, index) => {
    const firstIndex = businesses.findIndex((b) => b.id === business.id)
    return firstIndex === index
  })

  const filteredBusinesses = deduplicatedBusinesses.filter(
    (b) => filterRating <= (b.rating || 0) && b.userRatingCount >= minimumNumberOfRatings && keywords.find((k) => b.types.includes(k.keyword))?.active,
  )

  return { deduplicatedBusinesses, filteredBusinesses }
}



export function isPointInPolygon(point: L.LatLngTuple , polygon: Cluster['points']) {
  let [x, y] = point;
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let [xi, yi] = polygon[i];
      let [xj, yj] = polygon[j];

      let intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }

  return inside;
}



export function findClusterForCoordinates(coordinates: L.LatLngTuple, clusters: Cluster[]) {
  for (const cluster of clusters) {
      if (isPointInPolygon(coordinates, cluster.points)) {
          return cluster;
      }
  }
  return null; // or a default value, or throw an error, depending on your needs
}

export const rankHomes = (homes: Home[], keywords: Keyword[], filteredBusinesses: Business[]) => {
  const activeKeywords = keywords.filter((k) => k.active);
  const filteredAgainBusiness = filteredBusinesses.filter((b) => 
    activeKeywords.find((k) => b.types.includes(k.keyword))
  );

  const rankedHomes = homes.map((home) => {
    let score = 0;
    activeKeywords.forEach((keyword) => {
      const relatedBusinesses = filteredAgainBusiness.filter((business) => 
        business.types.includes(keyword.keyword)
      );
      let closestDistance = Infinity;
      relatedBusinesses.forEach((business) => {
        const distance = getDistanceFromLatLonInKM(
          home.geometry.lat,
          home.geometry.lng,
          business.location.latitude,
          business.location.longitude,
        );
        closestDistance = Math.min(closestDistance, distance);
      });
      if (isFinite(closestDistance)) {
        score += 1 / closestDistance; // Score increment for each keyword
      }
    });
    return { ...home, score };
  });

  return rankedHomes
    .sort((a, b) => b.score - a.score)
    .map((h, index) => ({ ...h, rank: index + 1 }));
}

export function getOrdinalSuffix(number: number): string {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits > 10 && lastTwoDigits < 14) {
      return number + "th";
  }

  switch (lastDigit) {
      case 1:
          return number + "st";
      case 2:
          return number + "nd";
      case 3:
          return number + "rd";
      default:
          return number + "th";
  }
}
